import React from 'react';
import {ButtonGroup, IconButton, ListItem, ListItemText, Typography} from "@mui/material";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import * as actions from "../../store/actions";
import {connect} from "react-redux";

// Element which contains one item of an option list
// can be either an existing item, or an "adding" option
function OptionElement(props) {

    const addItem = (id=null) => {
        if (props.admin) {
            props.writeToDB('update', props.type, props.level, id==="new" ? {} : {id: id}, props.newItem,
                ()=>props.getData(props.type,props.level,{},'replace'))
            props.editItem(null)
        }
    }

    const editItem = (id) => {

        if (props.admin) {
            props.editItem(id)

            let newItem = []
            Object.keys(props.newItem).forEach(key => {
                // console.log(key,props.item)
                newItem[key] = (id==="new") ? '' : props.item[key]
            })
            props.setNewItem(newItem)
        }
    }

    const removeItem = () => {
        if (props.admin) {
            const str = 'Are you sure you want to delete this report? All assignments of measures will be lost.'
            // console.log(props.type,props.level,props.item)
            if (window.confirm(str)) {
                props.writeToDB('remove', props.type, props.level, {'id': props.item.id}, {},
                    () => props.clearDataEntry(props.type,props.level,props.item.id)
                )
            }
        }
    }

    let primary, secondary = null
    let editOptions
    if (props.new && !props.editing) {
        editOptions = props.admin && <IconButton onClick={(e) => {
            editItem("new")
            e.stopPropagation()
            }}>
            <AddIcon/>
        </IconButton>

        primary = <Typography variant='body1'>Add new</Typography>
    }
    else {
        editOptions = props.admin && <ButtonGroup sx={{position: 'absolute',right:'1rem',top:'0.25rem'}}>
             <IconButton
                edge="end"
                aria-label="edit"
                onClick={(e) => {
                    editItem(props.editing ? null : props.item.id)
                    e.stopPropagation()
                }}
            >
                {props.editing ?
                    <DoDisturbIcon/> : <EditIcon/>}
            </IconButton>
            <IconButton
                edge="end"
                aria-label="add_delete"
                disabled={props.editing && !props.valid}
                onClick={props.editing ? (e) => {
                    addItem(props.item.id)
                    e.stopPropagation()
                } : (e) => {
                    removeItem(props.item.id)
                    e.stopPropagation()
                }}
            >
                {props.editing ?
                    <SaveIcon/> : <DeleteIcon/>}
            </IconButton>
        </ButtonGroup>
        const elements = props.buildItem(props.item,props.editing,props.new)
        primary = elements.primary
        secondary = elements.secondary
    }

    return <ListItem
        button
        key={props.item.id}
        onClick={() => props.changeItem(props.item.id)}
        selected={props.selected}
        alignItems='flex-start'>
        <ListItemText
            primary={primary}
            secondary={secondary}/>
        {editOptions}
    </ListItem>
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,setOption) => dispatch(actions.getData('fetch',type,level,filter,[],[],setOption,false)),
        writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
        changeReport: (id) => dispatch(actions.changeReport(id)),
    }
}

export default connect(null,mapDispatchToProps)(OptionElement);