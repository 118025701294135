import React from 'react';
import {Box, Divider, Toolbar } from "@mui/material";
import NavigationItems from "../NavigationItems/NavigationItems";
import ListSets from "../../Report/ListSets";
import ListReports from "../../Report/ListReports";

function DrawerContent(props) {

    return (
        <Box>
            <Toolbar/>
            <Divider/>
            <NavigationItems clicked={props.clicked}
                             admin={props.admin} superAdmin={props.superAdmin}
            />
            {props.additionalContent}
            <Divider sx={{marginBottom: '2rem'}}/>
            <ListSets title={'Choose set'} admin={props.superAdmin}/>
            <Divider/>
            {props.admin && <ListReports title={'Reports'} admin={props.superAdmin}/>}
        </Box>
    );
}

export default DrawerContent;