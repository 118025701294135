import React from 'react';
import {connect} from "react-redux";
import OptionElement from "./Elements/OptionElement";
import {List} from "@mui/material";

function OptionList(props) {

    let data = Object.values(props[props.type][props.level])

    let OptionListEl = data.map(d => <OptionElement key={d.id} {...d} type={props.type} level={props.level} />)
    OptionListEl.push(<OptionElement key='new' new={true} type={props.type} level={props.level}/>)

    return (
        <List sx={{margin:'1rem'}}>
            {OptionListEl}
        </List>
    );
}


const mapStateToProps = state => {
    return {
        measures: state.data.measures,
        reports: state.data.reports,
    }
}

export default connect(mapStateToProps)(OptionList);