import React, {useEffect} from 'react';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import ListUsers from "../../../components/Report/ListUsers";

function Users(props) {

    useEffect(async () => {
        // await props.isLoggedIn()
        props.getData('user','user',{},'replace')
    },[])

    return (
        <ListUsers title={'Registered users'} admin={props.isAuth}/>
    )
}

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        isLoggedIn: () => dispatch(actions.isLoggedIn()),
        getData: (type,level,filter,setOption) => dispatch(actions.getData('fetch',type,level,filter,[],[],setOption,false)),
        registerUser: (userInfo) => dispatch(actions.registerUser(userInfo)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Users);
