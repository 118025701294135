import React, {Component} from 'react';

import classes from './Contact.module.scss';

class Contact extends Component {

    render() {
        return (
            <div>
                <h1>Contact us</h1>
                <p>Contact Info: office address, <a href="mailto:europe@scout.org">europe(at)scout.org</a>, phone</p>
                <ul style={{listStyle:"none"}}>
                    <li><i className="fas fa-globe"></i> <a href="https://www.scout.org/europe">Our website</a></li>
                    <li><i className="fab fa-facebook"></i> <a href="https://www.facebook.com/ScoutinginEurope">Facebook</a></li>
                    <li><i className="fab fa-instagram"></i> <a href="??">Instagram</a></li>
                    <li><i className="fab fa-twitter"></i> <a href="https://twitter.com/ScoutingEurope">Twitter</a></li>
                </ul>
                <h2>Feedback</h2>
                <form>
                    <div className={classes.ContactForm}>
                        <div className={classes.ContactForm__Header}>
                            <input type="text" placeholder="Name"/>
                            <input type="text" placeholder="Email"/>
                        </div>
                        <textarea className={classes.ContactForm__Body} placeholder="TypeElement your message here"></textarea>
                        <button className={classes.ContactButton}>Send</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default Contact;
