import React, {useMemo} from 'react';
import {sortGRI} from "../../../shared/utility";
import {connect} from "react-redux";
import {Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubtopicList from "../Subtopic/SubtopicList";
// import {DisplayContext} from "../../../shared/DisplayContext";

function Topic(props) {

    // const displayContext = useContext(DisplayContext)
    // console.log(displayContext)
    // console.log('[Topic.js] rerendering')

    const expandThis = props.expanded===props.id

    // const filterDimension = props.griFilter.dimension[0]
    // const filterTopic = props.griFilter.topic[0]

    return useMemo(() => {
        // console.log('[Topic.js] (memo) rerendering',props.expanded)
        const subtopics = Object.values(props.gri_data.subtopic).filter(d => d.topic===props.topic && d.dimension===props.dimension)
        subtopics.sort(sortGRI)
        // console.log(subtopics)

        if (subtopics.length===0) {
            return null
        }

        return (<Accordion key={props.id} sx={{background: 'document.paper'}}
                           TransitionProps={{ unmountOnExit: true }}
                           expanded={expandThis}
                           onChange={() => props.toggleOpen(props.id)}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant='h6'>{props.GRI} - {props.description}</Typography>
                {/*{displayContext.adminRights && <FormControlLabel*/}
                {/*    control={<Switch />}*/}
                {/*    sx={{marginLeft: '1rem'}}*/}
                {/*    // checked={props.checked}*/}
                {/*    // onChange={props.changed}*/}
                {/*/>}*/}
            </AccordionSummary>
            <AccordionDetails>
                <SubtopicList
                    data={subtopics}
                />
            </AccordionDetails>
        </Accordion>
        );
    },[expandThis,props.loadStatus.subtopic.loaded])
}

const mapStateToProps = state => {
    return {
        gri_data: state.data.gri,
        loadStatus: state.data.loadStatus,
        // griFilter: state.data.griFilter,
    }
}

export default connect(mapStateToProps)(Topic);