import React, {useState} from 'react';

import {buildGRI} from '../../shared/utility';
import Goals from '../Lists/Goals/Goals';
// import Button from '../UI/Button/Button';

import DOMPurify from 'dompurify';
// import 'tippy.js/dist/tippy.css';

import classes from './Inspire.module.scss';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, Button,
    ButtonGroup,
    IconButton, Stack, TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers";
import GRIChipList from "../GRIDisplay/Chips/GRIChipList";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Input from "../UI/Input/Input";

const InspireItem = (props) => {

    const [editing,setEditing] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [values,setValues] = useState(
        {
            title: props.title ?? '',
            description: props.description ?? '',
            image: props.image,
            file: props.file,
            author: props.author ?? '',
            date: props.date ?? '',
            gri: props.subtopic_id ?? [],
            SDG_goal: props.SDG_goal ?? []
        })

    const GRIChangedHandler = (val) => {
        let gri = [...values.gri]
        if (gri.includes(val)) {
            const index = gri.indexOf(val)
            gri.splice(index,1)
        } else {
            gri.push(val)
        }
        setValues({...values, gri: gri})
    }

    const formChangedHandler = (t,id) => {
        let newValues = {...values}
        if (id==='image') {
            const path = URL.createObjectURL(t.files[0])
            newValues[id] = path
            newValues['file'] = t.files[0]
        } else {
            newValues[id] = t
        }
        setValues(newValues)
    }

    // console.log(props)
    // console.log(values)

    // const editEntry = (id) => {
    //     const project = this.props.inspire_data[this.state.type][id]
    //     const editValues = {}
    //     console.log('editing',id,project)
    //     Object.keys(this.state.values).forEach(key => {
    //         if (key==='image') {
    //             editValues[key] = project['file_url']
    //             // editValues['file'] = createFile(project['file_url'])
    //         } else {
    //             editValues[key] = project[key]
    //         }
    //     })
    //     this.setState({values: editValues, where: id})
    // }

    const submitData = () => {
        const submitValues = {...values}

        // obtain gri
        submitValues['gri'] = []
        values.gri.forEach(id => {
            if (id) {
                let gri = props.GRI_data.subtopic[id]
                submitValues['gri'].push({
                    subtopic_id: gri.id,
                    dimension: gri.dimension,
                    topic: gri.topic,
                    subtopic: gri.subtopic
                })
            }
        })
        submitValues['type'] = props.type
        const where = props.id ? {"id": props.id} : null
        props.writeToDB('update','inspire',props.type,where,submitValues,
            () => props.getData('inspire',props.type,where,[],'add',false)
        )
    }


    let titleEl = editing ? <TextField
        id='value'
        label='Title'
        variant='standard'// sx={{width: '10rem'}}
        value={values.title}
        onChange={(event) => setValues({...values, title: event.target.value})}
    /> : <Typography variant='h6'><strong>{props.title}</strong></Typography>

    let authorEl = editing ? <TextField
        id='value'
        label='Author'
        variant='standard'// sx={{width: '10rem'}}
        value={values.author}
        onChange={(event) => setValues({...values, author: event.target.value})}
        /> : <Typography variant='h6'><strong>{'published by: ' + props.author}</strong></Typography>

    // possibly make rich text?!
    let description = DOMPurify.sanitize( props.description );
    description = <Typography variant='body1' dangerouslySetInnerHTML={{ __html: description}}></Typography>
    let descriptionEl = editing ? <TextField
        id='value'
        label='Description'
        variant='standard' multiline rows={6} fullWidth
        value={values.description}
        onChange={(event) => setValues({...values, description: event.target.value})}
    /> : <Typography variant='h6'>{description}</Typography>

    let dateEl = editing ? <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
            label="Published at"
            variant='standard'
            value={values.date}
            onChange={(event) => (event ? setValues({...values, date: event}) : {})}
            renderInput={(params) => <TextField variant='standard' {...params} />}
        />
    </LocalizationProvider> : <p>{'published on: ' + (values.date ? values.date.toDateString() : null)}</p>

    let fileEl = (props.editRights && editing || props.new) && <Box sx={{position:'relative'}}>
        <input
            accept="image/*"
            hidden
            id="upload-file"
            // multiple
            disabled
            type="file"
            onChange={(event)=>formChangedHandler(event.target,'image')}
        />
        <label htmlFor="upload-file">
            <Button variant="contained" component='span' disabled>
                <AddIcon/>
                Upload image
            </Button>
        </label>
    </Box>

    let header
    let content = null

    // if (props.new) {
    //     header = <ButtonGroup>
    //         <IconButton onClick={() => setEditing(!editing)}>
    //             {editing ? <DoNotDisturbIcon/> : <AddIcon/>}
    //         </IconButton>
    //     </ButtonGroup>
    //
    // } else {
    header = <Stack width='100%' direction='row' justifyContent='space-between'>
        {/*className={classes.InspireElement__Header} onClick={() => {}}>*/}
        {/*{active ? null : <div style={{width:'50%'}}>*/}
        {titleEl}
        <ButtonGroup>
            {props.editRights && <IconButton onClick={() => setEditing(!editing)}>
                    {editing ? <DoNotDisturbIcon/> : <EditIcon/>}
                </IconButton>}
            <IconButton onClick={() => {
                if (editing) submitData()
                if (props.new || editing) setEditing(!editing)
                setExpanded(!expanded)
            }}>
                {editing ? <SaveIcon/> : props.new ? <AddIcon/> : <ExpandMoreIcon/>}
            </IconButton>
        </ButtonGroup>
    </Stack>



        // const data = values.gri.map((desc,i) => {
        //     const griName = buildGRI(props.dimension[i],props.topic[i],props.subtopic[i])
        //     return {gri: griName, description: desc, sdg: props.SDG_goal[i]}
        // })

        // let test = data.map(d => {
        //     return {gri: d.gri, description: d.description}
        // })

        // let griData = data.reduce((d,el) => {
        //         if (!d.map(el => el.gri).includes(el.gri))
        //             d.push({gri: el.gri, description: el.description})
        //         return d
        //     },[])

        let griListEl = <GRIChipList values={values.gri} editRights={editing} handleChange={GRIChangedHandler}/>
        const sdg = (!editing && values.SDG_goal.length>0) ? <Goals goals={values.SDG_goal} size={25}/> : null;

        // console.log()
        content = <div className={classes.InspireElement__content}>
            {/*<Typography variant='h2'>{props.title}</Typography>*/}
            <div className={classes.Content}>
                <div className={classes.multiSelectResults__div} style={{width:'100%'}}>
                    {sdg}
                </div>
                {griListEl}
                {fileEl}
                <div style={{width:'100%'}} >
                {(props.thumb_url || values.image) ?
                    <div className={classes.ImageDiv}>
                        <img src={values.image ?? props.thumb_url} alt={'Image of this '+ props.type}/>
                    </div> : null}
                    {/*<div style={{float:'none', overflow:'hidden'}}>*/}
                        {descriptionEl}
                    {/*</div>*/}
                    <br clear="left" />
                </div>
                <Stack direction='row' justifyContent='space-between'>
                    {authorEl}
                    {dateEl}
                </Stack>
            </div>
            {/*{props.edit ? <Button type="edit" clicked={() => setEditing(!editing)}/> : null}*/}
        </div>
    // }


    return <Accordion expanded={expanded || editing} sx={{width:'100%'}}>
            <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {header}
            </AccordionSummary>
            <AccordionDetails>
                {content}
            </AccordionDetails>
        </Accordion>
}

const mapStateToProps = state => {
    return {
        GRI_data: state.data.gri,
        SDG_data: state.data.sdg,
        // currentReportEntity: state.data.currentReportEntity,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false)),
        writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(InspireItem);