import React, {Component} from 'react';
import OptionList from "./OptionList";
import {Box, Stack, Typography} from "@mui/material";
import CO2_parametersList from "./CO2_parametersList";

class ManageOptions extends Component {
    render() {
        let listWidth = '250px'
        return (
            <div>
                <Box>
                    <Typography variant='h2'>CO2 parameter</Typography>
                    <CO2_parametersList />
                </Box>
                <Box>
                    <Typography variant='h2'>Measures</Typography>
                        <Stack direction='row' >
                            <Box sx={{width:listWidth}}>
                                <OptionList type='measures' level='unit'/>
                            </Box>
                            <Box sx={{width:listWidth}}>
                                <OptionList type='measures' level='type'/>
                            </Box>
                        </Stack>
                </Box>
                <Box>
                    <Typography variant='h2'>Reports</Typography>
                    <Stack direction='row' >
                        <Box sx={{width:listWidth}}>
                            <OptionList type='reports' level='frequency'/>
                        </Box>
                        <Box sx={{width:listWidth}}>
                            <OptionList type='reports' level='target_group'/>
                        </Box>
                    </Stack>
                </Box>
            </div>
        );
    }
}

export default ManageOptions;