import React, {Component} from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';

import {Box, CircularProgress, Typography} from "@mui/material";
import SubtopicList from "../../../components/GRIDisplay/Subtopic/SubtopicList";
import {DisplayContext} from "../../../shared/DisplayContext";

import classes from './SDGBreakdown.module.scss';

class SDGBreakdown extends Component {

    state = {
        dataLoaded: true,
        GRIid: null,
        discIDs: null,
        displayContext: {
            editRights: false,
            adminRights: false,
            filterIt: true,
            additionalFilter: null,
            showDisclosures: false
        }
    }

    componentDidMount() {
        this.props.getData('gri','subtopic',{set_id: this.props.currentSet, SDG_goal:this.props.SDG_ID},[],[],'replace',true)
    }

    sortGRI = (id1,id2) => {
        if (id2.applicability!==id1.applicability) {
            // console.log(id1)
            return id2.applicability - id1.applicability
        } else if (id2.priority!==id1.priority) {
            return id2.priority - id1.priority
        } else {
            return id2.measurability - id1.measurability
        }
    }


    render() {

        let subtopicInfo = <CircularProgress color="secondary" />
        if (this.props.loadStatus.subtopic.loaded) {
            let subtopics = Object.values(this.props.GRI_data.subtopic).sort(this.sortGRI)
            subtopics = subtopics.filter(subT => (parseFloat(subT.applicability)>5. && parseFloat(subT.priority)>5.))
            subtopicInfo = <DisplayContext.Provider value={this.state.displayContext}>
                <SubtopicList data={subtopics} />
            </DisplayContext.Provider>
        }


        return (
            <div>
                <Box className={classes.Description}>
                    <Typography variant={this.props.isMobile ? 'h4' : 'h2'}>Goal {this.props.SDG_ID}: {this.props.SDG_data.goal[this.props.SDG_ID].name}</Typography>
                    <Typography variant='body1'><em>{this.props.SDG_data.goal[this.props.SDG_ID].description}</em></Typography>
                    <Typography variant='body1'>Here could be some paragraph with a more detailed explanation of what this goal means for us, which aspects of our daily work are affected by it and what we are doing to contribute.</Typography>
                </Box>
                {subtopicInfo}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentSet: state.data.currentMeasureSet,

        isMobile: state.data.isMobile,

        SDG_data: state.data.sdg,
        GRI_data: state.data.gri,
        measure_data: state.data.measures,
        loadStatus: state.data.loadStatus
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,groupBy,setOption,setLoading,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,setOption,setLoading,callbackFcn)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SDGBreakdown);
