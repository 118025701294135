import React, {useState} from 'react';
import {ButtonGroup, IconButton, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import SaveIcon from "@mui/icons-material/Save";
// import AddIcon from "@mui/icons-material/Add";

function EditableText(props) {

    const [editing,setEditing] = useState(false)
    const [text,setText] = useState(props.text)

    let optionButtons = null
    if (props.saveOnButton && props.editable) {
        optionButtons = editing && <ButtonGroup sx={{margin:'auto'}}>
                    <IconButton onClick={() => setEditing(false)}><DoNotDisturbIcon /></IconButton>
                    <IconButton onClick={() => props.onSave({[props.tag]: text},() => setEditing(false))}><SaveIcon /></IconButton>
        </ButtonGroup>
    }

    return (editing && props.editable) ? <Stack sx={{flex:'1'}}>
            <TextField
                // id='textField'
                label={props.label}
                variant='standard' multiline fullWidth
                value={text}
                onChange={(event) => setText(event.target.value)}
                onBlur={!props.saveOnButton ? () => props.onSave({[props.tag]: text},() => setEditing(false)) : null}
                InputProps={props.saveOnButton && {'endAdornment': <InputAdornment position='end'>{optionButtons}</InputAdornment>}}
            />
            {/*{optionButtons}*/}
        </Stack> : <Typography
            variant={props.variant ?? 'standard'}
            sx={{flex: '1 1 0', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal', cursor: props.editable ? 'text' : 'inherit'}}
            onClick={props.editable ? () => setEditing(true) : null}
        >
            {text}
        </Typography>
}

export default EditableText;