import React, { Component } from 'react';
import { connect } from 'react-redux';

import InspireList from '../../components/Inspire/InspireList';
import Spinner from '../../components/UI/Spinner/Spinner';

import * as actions from '../../store/actions';

// import classes from './Projects.module.scss';

class Inspire extends Component {

    state = {
        type: null
    }

    loadData() {
        const type = this.props.location.pathname.split("/").at(-1);
        this.setState({type: type})
        // console.log('load', type)
        // this.props.resetData()
        // this.props.getData('sdg','goal',{},['SDG'],[],'replace')
        this.props.getData('gri','subtopic',{},[],[],'replace')
        this.props.getData('inspire',type,{},[],[],'replace')
    }

    componentDidMount () {
        this.loadData()
    }

    componentDidUpdate () {
        const type = this.props.location.pathname.split("/").at(-1);
        if (type!==this.state.type) {
            this.loadData()
        }
    }

    render() {
        let list = <Spinner />
        if (this.state.type && this.props.loadStatus[this.state.type].loaded && !this.props.loadStatus[this.state.type].loading) {
            list = <InspireList type={this.state.type} data={this.props.inspire_data[this.state.type]} edit={false}/>
        }

        return (
            <div>
                {list}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loadStatus: state.data.loadStatus,
        inspire_data: state.data.inspire
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetData: () => dispatch(actions.resetData()),
        getData: (type,level,filter,links,setOption,setLoading,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,setOption,setLoading,callbackFcn)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Inspire)
