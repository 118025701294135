import React from 'react';
import {Card as MUICard, CardActions, CardContent, Typography} from "@mui/material";

function Card(props) {
    return (
        <MUICard sx={{position:'relative', ...props.styles}}>
            <CardContent>
                {typeof props.title==='string' ? <Typography variant='h6'>{props.title}</Typography> : props.title}
                {typeof props.body==='string' ? <Typography>{props.body}</Typography> : props.body}
            </CardContent>
            {props.action ? <CardActions>
                {props.action}
            </CardActions> : null}
        </MUICard>
    );
}

export default Card;