import React from 'react';

import classes from './ProgressBar.module.scss';
import {Stack, Tooltip} from "@mui/material";
import {ThumbDown, ThumbUp} from "@mui/icons-material";

const ProgressBar = (props) => {
    const progress = props.progress ?? 0
    const breakPoint = props.breakPoint ?? 80

    let textStyle = {left: progress+'%',color: 'black'}
    if (progress > breakPoint) {
        textStyle = {right: (100-progress)+'%', color: 'white'}
    }

    const unit_string = (props.unit==='None') ? '' : ' ' + props.unit
    let title = props.title ?? 'No value reported'
    let value = props.value ?? props.total_value
    if (!props.title && !!value) {
        title = 'Reported value: ' + value + unit_string
    }

    return <Stack direction='row' maxWidth='240px'>
        {props.thumbs && <Tooltip
            title={'Baseline: ' + props.baseline + unit_string}
            enterTouchDelay={10}>
                <ThumbDown sx={{marginTop:'5px'}}/>
            </Tooltip>}
            <Tooltip
                title={title}
                enterTouchDelay={10}>
                <div className={classes.ProgressBar} style={{marginTop:'6px', marginBottom:'6px'}}>
                    <div
                        className={classes.ProgressBar__Progress}
                        style={{width: progress + '%'}}>
                    </div>
                <p style={textStyle}>{progress.toFixed(1) + '%'}</p>
                </div>
            </Tooltip>
        {props.thumbs && <Tooltip title={'Target: ' + props.target + unit_string}>
                <ThumbUp />
            </Tooltip>}
    </Stack>
}

export default ProgressBar;
