import React, {useEffect, useState} from 'react';

import classes from './Layout.module.css';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {
    Snackbar,
    Alert,
    Toolbar,
    Typography,
    Box,
    CssBaseline,
    AppBar,
    IconButton,
    Drawer, Button
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerContent from "../../components/Navigation/SideDrawer/DrawerContent";
import {NavLink} from "react-router-dom";
import {useHistory} from "react-router-dom";

const drawerWidth = 240;

function Layout (props) {

    let snackBar = null
    if (props.snacks) {
        snackBar = <Snackbar open={true} autoHideDuration={6000} onClose={props.closeSnackbar}>
            <Alert onClose={props.closeSnackbar} severity={props.snacks.severity} sx={{width: '100%'}}>
                {props.snacks.message}
            </Alert>
        </Snackbar>
    }

    useEffect(() => {
        props.checkMobile(window.innerWidth)
    },[window.innerWidth])

    // const { window } = props;
    // const container = window !== undefined ? () => window().document.body : undefined;

    const [mobileOpen, setMobileOpen] = useState(false);
    // let history = useHistory();

    const log_in_out = <NavLink
        to={props.isAuth ? "/" : "/login"} exact
        // activeClassName={classes.active}
        style={{color: 'white', textDecoration:'none', fontWeight: '500', margin: '0 1rem'}}
        onClick={props.isAuth ? () => {
            props.logoutUser()
        } : null}
    >{props.isAuth ? 'Logout' : 'Login'}</NavLink>

    const roles = props.theUser ? props.theUser.role_id.map(id => parseInt(id)) : []
    const drawer = <DrawerContent clicked={() => setMobileOpen(false)}
                                  admin={props.isAuth} superAdmin={roles.includes(1)}
    />
    //superAdmin={props.theUser?.role_id.includes(1)}


    const toolbarHeader = <>
        <Typography variant={props.isMobile ? 'h6' : 'h4'} sx={{flex:'1'}}>A Sustainable Region</Typography>
        {!props.isMobile && log_in_out}
        <div style={{position:'relative',height:'80px', top: '10px'}}>
            <img src='/uploads/Logos/WOSM.png' style={{height:'100%'}}/>
        </div>
    </>


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={classes.Toolbar}
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: '#622599'
                }}
            >
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        onClick={() => setMobileOpen(true)}
                        aria-label="menu"
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {toolbarHeader}
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                // aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {props.isMobile ? <Drawer
                    // container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={() => setMobileOpen(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer> :
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>}
            </Box>
            <Box
                component="main"
                // className={classes.Content}
                sx={{width: props.isMobile ? '100%' : { sm: `calc(100% - ${drawerWidth}px)` }}}
            >
                <div className={classes.Content}>
                    {props.children}
                </div>
            </Box>
            {snackBar}
        </Box>
    );
};


const mapStateToProps = (state) => {
    return {
        snacks: state.status.snacks,

        isMobile: state.data.isMobile,
        isAuth: state.auth.isAuth,
        theUser: state.auth.theUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeSnackbar: () => dispatch(actions.closeSnackbar()),
        logoutUser: () => dispatch(actions.logoutUser()),
        checkMobile: (width) => dispatch(actions.checkMobile(width)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Layout)
