import React from 'react';

import InspireItem from './InspireItem';

import classes from './Inspire.module.scss';
import {Typography} from "@mui/material";
import {connect} from "react-redux";



const InspireList = (props) => {

    const items = Object.values(props.data).map(d => <InspireItem key={d.id} clicked={props.clicked} editRights={props.editRights} {...d}/>)

    if (props.editRights) {
        items.unshift(
            <InspireItem key='new' clicked={props.clicked} new={true} />
        )
    }

    return (
        <div className={classes.InspireList}>
            <Typography variant={props.isMobile ? 'h3' : 'h2'}>{props.type}</Typography>
            {items}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isMobile: state.data.isMobile
    }
}
export default connect(mapStateToProps)(InspireList);
