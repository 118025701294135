import React, {useState} from 'react';
import {Button, Menu, MenuItem} from "@mui/material";
import {withRouter} from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

function Dropdown(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        if (props.onClick) props.onClick()
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={props.addClasses}
            >
                {props.ddHeader.title}
                <KeyboardArrowDownIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props.ddList.map((item) =>
                    <MenuItem
                        key={item.id}
                        onClick={() => {
                            handleClose()
                            props.history.push(props.ddHeader.href + item.href)
                        }}
                    >
                        {item.title}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}

export default withRouter(Dropdown);