export const SET_DATA = 'SET_DATA';
export const CLEAR_DATA_ENTRY = 'CLEAR_DATA_ENTRY';
export const SET_STATS = 'SET_STATS';
export const SET_COUNTS = 'SET_COUNTS';
export const RESET_DATA = 'RESET_DATA';
export const SET_LOAD_STATUS = 'SET_LOAD_STATUS';

export const CHANGE_MEASURE_SET = 'CHANGE_MEASURE_SET';
export const CHANGE_REPORT = 'CHANGE_REPORT';
export const CHANGE_REPORTENTITY = 'CHANGE_REPORTENTITY';
export const SELECT_GRI = 'SELECT_GRI';


export const ADD_SNACK = 'ADD_SNACK';
export const CLOSE_SNACK = 'CLOSE_SNACK';

export const CHECK_AUTH = 'CHECK_AUTH';
export const SET_USER = 'SET_USER';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION_CALLBACK = 'REGISTRATION_CALLBACK';

export const CHECK_MOBILE = 'CHECK_MOBILE';