import React from 'react';

import classes from './Button.module.scss';

const button = (props) => {

    let classesAdd = [classes.Button];
    let icon = null;
    switch (props.type) {
        case 'add':
            classesAdd.push(classes.btnAdd);
            icon = <i className="fas fa-plus" ></i>;
            break;
        case 'edit':
            classesAdd.push(classes.btnEdit);
            icon = <i className="fas fa-edit" ></i>;
            break;
        case 'check':
            classesAdd.push(classes.btnCheck);
            // icon = <i className="fas fa-edit" ></i>;
            break;
        case 'delete':
            classesAdd.push(classes.btnDelete);
            icon = <i className="fas fa-minus" ></i>;
            break;
        case 'save':
            classesAdd.push(classes.btnSave);
            icon = <i className="fas fa-save"></i>;
            break;
        case 'close':
            classesAdd.push(classes.btnClose);
            icon = <i className="fas fa-times"></i>;
            break;
        case 'expand':
            classesAdd.push(classes.btnExpand);
            icon = <i className="fas fa-expand-alt"></i>;
            break;
        case 'link':
            classesAdd.push(classes.btnLink);
            icon = <i className="fas fa-link"></i>;
            break;
        case 'back':
            classesAdd.push(classes.btnBack);
            icon = <i className="fas fa-long-arrow-alt-left"></i>;
            break;
        case 'trash':
            classesAdd.push(classes.btnTrash);
            icon = <i className="fas fa-trash fas-xs"></i>;
            break;
        case 'restore':
            classesAdd.push(classes.btnRestore);
            icon = <i className="fas fa-redo fa-xs"></i>;
            break;
        case 'upload':
            classesAdd.push(classes.btnUpload);
            icon = <i className="fas fa-upload"></i>;
            break;
        case 'left':
            classesAdd.push(classes.btnLeft);
            icon = <i className="fas fa-caret-left"></i>;
            break;
        case 'right':
            classesAdd.push(classes.btnRight);
            icon = <i className="fas fa-caret-right"></i>;
            break;
        case 'shrink':
            classesAdd.push(classes.btnShrink);
            icon = <i class="fas fa-compress-alt"></i>
            break;
        default:
            break;
    }
    classesAdd.push(props.classesAdd)
    return (
        <button className={classesAdd.join(' ')} onClick={props.clicked}>{icon}{props.children}</button>
    )
};

export default button;
