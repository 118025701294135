import axios from '../../axios';
import * as actionTypes from './actionTypes';

import * as actions from './index';
import * as status from "./status";

export const loginUser = (user) => {
    return dispatch => {
        // Sending the user Login request
        axios.post('login.php',{
            email: user.email,
            password: user.password
        })
            .then(response => {
                if (response.data.success && response.data.token) {
                    localStorage.setItem('loginToken', response.data.token);
                    dispatch(isLoggedIn());
                    dispatch(status.addSnackbar('success','Successfully logged in'))
                } else {
                    // console.log(response.data)
                    dispatch(loginFailed(response.data))
                    dispatch(status.addSnackbar('error','Login failed'))
                }
            })
            .catch(e => console.log('something went wrong: ' + e))
    }
}

export const isLoggedIn = () => {
    return async dispatch => {
        dispatch(checkAuth(true))
        const loginToken = localStorage.getItem('loginToken');
        // console.log('is logged in?')
        // If inside the local-storage has the JWT token
        if (loginToken) {
            const headers = null;

            await axios.post('apiAdmin.php',{
                    option: 'userInfo',
                    Authorization: 'Bearer ' + loginToken
                 }, headers )
                .then(response => {
                    // console.log(response.data)
                    if(response.data.success && response.data.user) {
                        dispatch(setUser(response.data.user))
                    } else {
                        dispatch(checkAuth(false))
                    }
                }).catch(e => console.log('database connection error'))
        }
    }
}

export const updatePassword = (form,user) => {

    return dispatch => {

        const loginToken = localStorage.getItem('loginToken');
        // If inside the local-storage has the JWT token
        if (loginToken) {
            // Sending the user registration request
            axios.post('apiAdmin.php',{
                    option: 'changePassword',
                    superUser: user.superUser,
                    name: user.name,
                    old_password: form.old_password.value,
                    new_password: form.new_password.value,
                    new_password_2: form.new_password_2.value,
                    Authorization: 'bearer ' + loginToken
                })
                // .then((response) => {
                //     dispatch(actions.messageDisplay(response.data.message,response.data.success));
                // }).catch(() => {
                //     dispatch(actions.messageDisplay('Passwort-update fehlgeschlagen',false));
            // })
        }
        // else {
            // dispatch(actions.messageDisplay('Update fehlgeschlagen: nicht eingeloggt',false));
        // }

    }
}

export const updateStatus = (user) => {

    return dispatch => {

        const loginToken = localStorage.getItem('loginToken');
        // If inside the local-storage has the JWT token
        if (loginToken) {
            // Sending the user registration request
            axios.post('apiAdmin.php',{
                    option: 'changeStatus',
                    name: user.name,
                    status: user.active,
                    Authorization: 'bearer ' + loginToken
                })
                .then((response) => {
                    // dispatch(actions.messageDisplay(response.data.message,response.data.success));
                }).catch(() => {
                    // dispatch(actions.messageDisplay('Status-update fehlgeschlagen',false));
            })
        }
        // else {
        //     dispatch(actions.messageDisplay('Update fehlgeschlagen: nicht eingeloggt',false));
        // }

    }
}

export const updateRole = (user) => {

    return dispatch => {

        const loginToken = localStorage.getItem('loginToken');
        // If inside the local-storage has the JWT token
        if (loginToken) {
            // Sending the user registration request
            axios.post('apiAdmin.php',{
                    option: 'changeRole',
                    name: user.name,
                    role: user.role,
                    Authorization: 'bearer ' + loginToken
                })
                .then((response) => {
                    // dispatch(actions.messageDisplay(response.data.message,response.data.success));
                }).catch(() => {
                    // dispatch(actions.messageDisplay('Rollen-update fehlgeschlagen',false));
            })
        }
        // else {
        //     dispatch(actions.messageDisplay('Update fehlgeschlagen: nicht eingeloggt',false));
        // }

    }
}

const setUser = (userData) => {
    return {
        type: actionTypes.SET_USER,
        userData: userData
    }
}

const loginFailed = (error) => {
    return {
        type: actionTypes.LOGIN_FAILED,
        message: error.message
    }
}

export const logoutUser = () => {
    return {
        type: actionTypes.LOGOUT
    }
}

export const checkAuth = (status) => {
    return {
        type: actionTypes.CHECK_AUTH,
        status: status
    }
}

export const registerUser = (user) => {

    return dispatch => {
        const loginToken = localStorage.getItem('loginToken');
        // If inside the local-storage has the JWT token
        if (loginToken) {
        // Sending the user registration request
            axios.post('apiAdmin.php',{
                    option: 'createUser',
                    name: user.name,
                    email: user.email,
                    role_id: user.role_id,
                    password: user.password,
                    repeat_password: user.repeat_password,
                    Authorization: 'bearer ' + loginToken
                })
                .then((response) => {
                    dispatch(status.addSnackbar('success','Successfully created account'))
                    // dispatch(actions.getData())
                    // dispatch(actions.messageDisplay(response.data.message,response.data.success));
                }).catch((e) => {
                    dispatch(status.addSnackbar('error','Creation of account failed'))
                    // dispatch(actions.messageDisplay('Registrierung fehlgeschlagen',false));
                })
            }
            // else {
            //     dispatch(actions.messageDisplay('Registrierung fehlgeschlagen: nicht eingeloggt',false));
            // }
    }
}

export const deleteUser = (form,user) => {

    return dispatch => {
        const loginToken = localStorage.getItem('loginToken');
        // If inside the local-storage has the JWT token
        if (loginToken) {
            // Sending the user registration request
            axios.post('apiAdmin.php',{
                    option: 'deleteUser',
                    superUser: user.superUser,
                    name: user.name,
                    password: form.password.value,
                    Authorization: 'bearer ' + loginToken
                }).then((response) => {
                    dispatch(actions.getData())
                    // dispatch(actions.messageDisplay(response.data.message,response.data.success));
                }).catch(() => {
                    // dispatch(actions.messageDisplay('Nutzer konnte nicht gelöscht werden',false));
            })
        }
        // else {
        //     dispatch(actions.messageDisplay('Nutzer wurde nicht gelöscht: nicht eingeloggt',false));
        // }
    }
}

export const registrationCallback = (msg,status) => {
    return {
        type: actionTypes.REGISTRATION_CALLBACK,
        msg: msg,
        status: status
    }
}
