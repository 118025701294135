import React, {useContext, useMemo} from 'react';
import Subtopic from "./Subtopic";
import {DisplayContext} from "../../../shared/DisplayContext";
import {connect} from "react-redux";
import {Box} from "@mui/material";

function SubtopicList(props) {

    const context = useContext(DisplayContext)
    const filterIt = context.filterIt

    // console.log('[SubtopicList.js] rerendering')

    return useMemo(() => {
        // console.log('[SubtopicList.js] (memo) rerendering')
        let subtopics = filterIt ? Object.values(props.data).filter(d => props.measure_counts['subtopic'][d.GRI] > 0) : Object.values(props.data)
         const els = subtopics.map(d =>
            <Subtopic
                key={d.id}
                {...d}/>
        )
        return <Box>{els}</Box>
    },[filterIt])
}

const mapStateToProps = state => {
    return {
        measure_counts: state.data.counts.timeful,
    }
}

export default connect(mapStateToProps)(SubtopicList);