import React, {memo, useState} from 'react';
import {ButtonGroup, FormControlLabel, IconButton, Stack, Switch, TextField, Tooltip, Typography} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TimelineIcon from "@mui/icons-material/Timeline";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import classes from "./Measures.module.scss";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import AddIcon from "@mui/icons-material/Add";
import Co2Icon from '@mui/icons-material/Co2';
import EditableText from "../UI/Input/EditableText";

const AdminOptions = memo(({isActive=false,report=null,inReport=false,isTracked=false,updateCheckboxes=()=>{},updateReport=()=>{}}) => {
    // console.log('creating AdminOptions')
    return (
        <ButtonGroup sx={{width: 'fit-content', minWidth:100, display:'flex', pointerEvents: 'all'}}>
            <Tooltip title={!isActive ? 'Activate measure' : 'Deactivate measure'}>
                <FormControlLabel
                    control={<Switch />}
                    checked={isActive}
                    style={{padding: 0, margin: 0}}
                    onChange={() => updateCheckboxes('active',!isActive)}
                />
            </Tooltip>
            <Tooltip title={!isActive ? 'Activate measure to enable option' : inReport ? `Remove from "${report}"` : `Add to "${report}"`}>
                <span>
                    <IconButton
                        disabled={!isActive || !report}
                        onClick={updateReport}>
                        <AssignmentIcon
                            color={!isActive ? 'disabled' : (inReport ? 'primary' : 'active')}
                        />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={!isActive ? 'Activate measure to enable option' : isTracked ? 'Do not track progress' : 'Track progress'}>
                <span>
                    <IconButton
                        disabled={!isActive}
                        onClick={() => updateCheckboxes('progress_tracked',!isTracked)}>
                        <TimelineIcon
                            color={!isActive ? 'disabled' : (isTracked ? 'primary' : 'active')}
                        />
                    </IconButton>
                </span>
            </Tooltip>
        </ButtonGroup>
    )
},(prevProps,nextProps) => {

    let areEqual = prevProps.isActive===nextProps.isActive
    areEqual = areEqual && prevProps.report===nextProps.report
    areEqual = areEqual && prevProps.inReport===nextProps.inReport
    areEqual = areEqual && prevProps.isTracked===nextProps.isTracked
    areEqual = areEqual && prevProps.currentSet===nextProps.currentSet

    return areEqual
})

const EditOptions = memo(({adminRights=false,newEntry=false,editing=false,toggleEditing,showData=false,toggleShowData,toggleChangeParameter,addMeasure,removeMeasure,text}) => {
    return (
          <ButtonGroup sx={{flex: '0 1 auto', width: 'fit-content', display: 'flex'}}>
            {(adminRights && !newEntry) && <>
                <IconButton onClick={toggleChangeParameter}>
                    <EditIcon/>
                </IconButton>
                <Tooltip title='Delete this measure'>
                    <IconButton onClick={removeMeasure}>
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </>}
            {(newEntry && editing) && <IconButton onClick={() => addMeasure(text)}>
                <SaveIcon />
            </IconButton>}
            <Tooltip title={newEntry ? (editing ? 'Cancel' : 'Add new') : (showData ? 'Hide data' : 'Show data')}>
                {!newEntry ? <IconButton onClick={toggleShowData}>
                    <ArrowDropDownIcon className={showData ? classes.rotateIcon : null}/>
                </IconButton> : <IconButton onClick={toggleEditing}>
                    {editing ? <DoNotDisturbIcon /> : <AddIcon /> }
                </IconButton>}
            </Tooltip>
        </ButtonGroup>
    )
},(prevProps,nextProps) => {

    let areEqual = prevProps.editing===nextProps.editing
    areEqual = areEqual && prevProps.showData===nextProps.showData
    areEqual = areEqual && prevProps.text===nextProps.text

    return areEqual
})

function MeasureHeader(props) {

    // console.log('[MeasureHeader.js] rerendering')

    const [editing, setEditing] = useState(false)
    const [newMeasureText, setNewMeasureText] = useState("")

    const updateCheckboxes = (element,value) => {
        // console.log('updating', element, 'to',value)

        let filter = {measure_id:props.id, set_id:props.currentSet}
        props.writeToDB('update','measures','timeful', {measure_id:props.id, set_id:props.currentSet}, {[element]:value},
            ()=>{
                props.getData('measures','timeful',filter,[],'add',props.onDataChange)
            })
    }

    const updateReport = () => {
        // console.log('updating report',report,'with id',id)
        // const data = {measure_id: id, report_id: report}
        let data = {measure_id: props.measure_id, report_id:props.currentReport}
        props.writeToDB('update','reports','link',data,data,
            ()=>{
                props.getData('measures','timeful',{measure_id:props.id, set_id:props.currentSet},[],'add',props.onDataChange)
            })
    }

    const addMeasure = (text) => {
        const where = {
            set_id: props.currentSet,
            dimension: props.dimension,
            topic: props.topic,
            subtopic: props.subtopic,
            disclosure: props.disclosure
        }
        const data = {
            disclosure_id: props.disclosure_id,
            description: text,
            ...where
        }
        props.writeToDB('update','measures','timeless',{},data,
            () => props.getData('measures','timeful',where,[],'add',setEditing(false))
        )
    }

    const removeMeasure = () => {
        const str = 'Are you sure you want to delete this measure from the dataset? You will not be able to retrieve it and all connected datapoints will be lost.'
        if (window.confirm(str)) {
            props.writeToDB('remove','measures','timeless',{'id':props.id},{},
                () => props.clearDataEntry('measures','timeful',props.id)
            )
        } else {
            console.log('not deleting')
        }
    }

    // const updateMeasure = (id,value) => {
    // const updateMeasure = (data,callback) => {
    //
    //     let where = {'id': props.id, 'set_id': props.currentSet}
    //     // const data = {[id]:value}
    //
    //     props.writeToDB('update','measures','timeless',where,data,
    //         () => props.getData('measures','timeful',{'measure_id': props.id, 'set_id': props.currentSet},[],'add',callback)
    //     )
    // }

    // const gri = buildGRI(props.dimension,props.topic,props.subtopic,props.disclosure)


    let report_ids = props.report_id ? props.report_id.map(id => parseInt(id)) : []
    const adminOptions = (props.adminRights && !props.new) && <AdminOptions
        currentSet={props.currentSet}
        isTracked={props.isTracked}
        report={props.reportData[props.currentReport]?.title ?? null}
        inReport={report_ids.includes(parseInt(props.currentReport))}
        isActive={props.active===1}
        // onDataChange={props.onDataChange}
        updateCheckboxes={updateCheckboxes}
        updateReport={updateReport}
    />

    const editOptions = props.editRights && <EditOptions
        adminRights={props.adminRights}
        newEntry={props.new}
        editing={editing}
        toggleEditing={() => setEditing(!editing)}
        showData={props.showData}
        toggleShowData={props.toggleShowData}
        toggleChangeParameter={props.toggleChangeParameter}
        addMeasure={addMeasure}
        removeMeasure={removeMeasure}
        text={newMeasureText}
    />

    let headerEl
    if (props.new) {
        headerEl = <Stack direction='row' sx={{width:'100%', justifyContent:'space-between', alignItems:'center'}}>
            {editing ? <TextField
                id='title'
                label={'Description'}
                variant='standard' multiline fullWidth
                value={newMeasureText}
                onChange={(event) => setNewMeasureText(event.target.value)}
            /> : <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'normal'}}>
                    Add new measure
                </Typography>}
            {editOptions}
        </Stack>
    } else {
        const textEl = <EditableText
            label={'Description'}
            editable={props.adminRights}
            tag={'description'}
            text={props.description}
            onSave={(data,callback) => props.updateMeasure(data,callback)} />

        if (props.isMobile) {
            headerEl = <Stack direction='column' sx={{width:'100%', justifyContent:'space-between', alignItems:'center'}}>
                <Stack direction='row'>
                    {textEl}
                    {props.CO2===1 && <Tooltip title='This measure impacts the CO2 footprint'><Co2Icon sx={{color:'green'}} /></Tooltip>}
                </Stack>
                <Stack direction='row'>
                    {adminOptions}
                    {editOptions}
                </Stack>
            </Stack>
        } else {
            headerEl = <Stack direction='row' sx={{width:'100%', justifyContent:'space-between', alignItems:'center'}}>
                {adminOptions}
                {textEl}
                {props.CO2===1 && <Tooltip title='This measure impacts the CO2 footprint'><Co2Icon sx={{color:'green'}} /></Tooltip>}
                {editOptions}
            </Stack>
        }
    }

    return headerEl;
}

const mapStateToProps = state => {
    return {
        reportData: state.data.reports.report,

        isMobile: state.data.isMobile,

        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MeasureHeader);