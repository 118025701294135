import React, {useState} from 'react';
import {FormControl, Select } from "@mui/material";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OptionList from "../Lists/OptionList";

function ListReports(props) {

    const [newReport, setNewReport] = useState({
        title: '',
        description: '',
        frequency_id: '',
        target_group_id: '',
    })

    const [valid, setValid] = useState(false)

    const changeData = (target) => {
        const key = target.name
        const val = target.value
        const updatedReport = {...newReport, [key]:val}
        validate(updatedReport)
        setNewReport(updatedReport)
    }

    const validate = (item) => {
        let newValid = true
        newValid = newValid && item.title.length>0
        newValid = newValid && item.description.length>0
        newValid = newValid && item.frequency_id>0
        newValid = newValid && item.target_group_id>0

        setValid(newValid)
    }

    const buildItem = (item,editing) => {

        // building select options
        let primary, secondary
        if (editing) {

            let freq_opt = Object.values(props.reports_data.frequency).map(f => {
                return <MenuItem key={f.id} value={f.id}>{f.title}</MenuItem>
            })
            let target_group_opt = Object.values(props.reports_data.target_group).map(t => {
                return <MenuItem key={t.id} value={t.id}>{t.title}</MenuItem>
            })

            primary = <div>
                <TextField
                    id='setName'
                    label={'Specify report name'}
                    variant='standard'
                    name='title'
                    error={!newReport.title}
                    value={newReport.title}
                    onChange={(event) => changeData(event.target)}/>
                <FormControl variant='standard' sx={{m: 1, width: '8rem'}}>
                    <InputLabel id='frequency'>Frequency</InputLabel>
                    <Select
                        required
                        error={newReport.frequency_id==0}
                        labelId='frequency' name='frequency_id'
                        value={newReport.frequency_id}
                        onChange={(event) => changeData(event.target)}>
                        {freq_opt}
                    </Select>
                </FormControl>
                <FormControl variant='standard' sx={{m: 1, width: '8rem'}}>
                    <InputLabel id='target_group'>Target group</InputLabel>
                    <Select
                        required
                        error={newReport.target_group_id==0}
                        labelId='target_group' name='target_group_id'
                        value={newReport.target_group_id}
                        onChange={(event) => changeData(event.target)}>
                        {target_group_opt}
                    </Select>
                </FormControl>
                <TextField
                    id='setDescription'
                    label='Report description'
                    name='description'
                    multiline fullWidth
                    error={!newReport.description}
                    value={newReport.description}
                    rows={4}
                    onChange={(event) => changeData(event.target)}/>
            </div>

            secondary = null
        } else {
            primary = item.title
            secondary = item.description
        }

        return {primary: primary, secondary: secondary}
    }

    return <OptionList
        data={props.reports_data.report}
        title={props.title}
        type='reports' level='report'
        selected={props.currentReport}
        admin={props.admin}
        valid={valid}
        newItem={newReport}
        setNewItem={setNewReport}
        buildItem={buildItem}
        changeItem={props.changeReport}
    />
}

const mapStateToProps = state => {
    return {
        reports_data: state.data.reports,

        currentReport: state.data.currentReport,
        loadStatus: state.data.loadStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getData: (type,level,filter,setOption) => dispatch(actions.getData('fetch',type,level,filter,[],[],setOption,false)),
        // writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
        // clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
        changeReport: (id) => dispatch(actions.changeReport(id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListReports);