import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    snacks: null,
}

const addSnackbar = (state,action) => {
    // const newSnacks = {...state.snacks}
    // newSnacks[action.id] = action.data
    return updateObject(state,{snacks: {severity: action.severity, message: action.message}})
}

const closeSnackbar = (state,action) => {
    // const newSnacks = {...state.snacks}
    // delete newSnacks[action.id]
    return updateObject(state,{snacks: null})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_SNACK:
            return addSnackbar(state,action);
        case actionTypes.CLOSE_SNACK:
            return closeSnackbar(state,action);
        default:
            return state;
    }
}


export default reducer;