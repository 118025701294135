import React, {useContext, useEffect, useMemo, useState} from 'react';
import MeasureList from "../../Measures/MeasureList";
import {sortGRI} from "../../../shared/utility";
import {connect} from "react-redux";
import {Stack, Typography} from "@mui/material";
import DOMPurify from "dompurify";
import {DisplayContext} from "../../../shared/DisplayContext";
import * as actions from "../../../store/actions";
import Goals from "../../Lists/Goals/Goals";
import classes from "../GRIDisplay.module.scss";

function Disclosure(props) {

    const context = useContext(DisplayContext)
    const adminRights = context.adminRights
    const showDisclosures = context.showDisclosures

    const [dataChanged,setDataChanged] = useState(false)

    // console.log('[Disclosure.js] rerendering')

    useEffect(() => {
        props.getData('measures','timeful',{disclosure_id:props.id, set_id:props.currentSet, ...context.additionalFilter},[],'add')
    },[])

    const measures = Object.values(props.measures_data.timeful).filter(d => d.disclosure===props.disclosure && d.subtopic===props.subtopic && d.topic===props.topic && d.dimension===props.dimension)
    measures.sort(sortGRI)

    let description = null
    if (showDisclosures) {
        description = DOMPurify.sanitize( props.description );
        description = <Typography variant={'body1'} dangerouslySetInnerHTML={{ __html: '<p style="margin-right: 0.5rem">'+ props.disclosure + ')</p>' + description}} sx={{marginLeft:'2rem'}}></Typography>
    }
    // console.log(props)

    // return useMemo(() => {
    //     console.log('[Disclosure.js] rerendering')

        return (
            <>
                <Stack direction="row" gap={2}>
                    {showDisclosures && <>
                        <div className={classes.DescriptionWrapper}>
                            {description}
                        </div>
                        <div style={{width: '15%', display: 'flex'}}>
                            <Goals
                                goals={props.SDG_goal}
                                size={25}
                                editable={context.adminRights}
                                onEdit={(goal_id) => context.selectGoal(props.dimension, props.topic, props.subtopic, props.disclosure, goal_id)}/>
                        </div>
                    </>}
                </Stack>
                <MeasureList
                    data={measures}
                    dimension={props.dimension}
                    topic={props.topic}
                    subtopic={props.subtopic}
                    disclosure={props.disclosure}
                    disclosure_id={props.id}
                    adding={adminRights && showDisclosures}
                    // onDataChange={() => setDataChanged(!dataChanged)}
                />
            </>
        )
    // },[adminRights,showDisclosures,dataChanged]);
}

const mapStateToProps = state => {
    return {
        measures_data: state.data.measures,

        currentSet: state.data.currentSet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Disclosure);