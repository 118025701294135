import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {Stack} from "@mui/material";
import OptionList from "../Lists/OptionList";
import {connect} from "react-redux";
import MultipleSelect from "../UI/Input/MultipleSelect";

function ListUsers(props) {

    const [newUser,setNewUser] = useState({
        name:'Alex',
        email:'alexander.schmidt@pfadfinden.de',
        role_id:[],
        password:'',
        repeat_password:'',
    })

    const [valid, setValid] = useState(false)

    const changeData = (target) => {
        const key = target.name
        const val = target.value
        const updatedReport = {...newUser, [key]:val}
        validate(updatedReport)
        setNewUser(updatedReport)
    }

    const validate = (item) => {
        let newValid = true
        newValid = newValid && item.name.length>0
        newValid = newValid && item.email.length>0
        newValid = newValid && item.role_id.length>0
        newValid = newValid && item.password.length>0
        newValid = newValid && item.repeat_password.length>0
        newValid = newValid && item.repeat_password===item.password
        setValid(newValid)
    }

    const buildItem = (item,editing) => {

        // building select options
        let primary, secondary
        if (editing) {
            // let roles = Object.values(props.user_data.role).map(r => {
            //     return <MenuItem key={r.id} value={r.id}>{r.title}</MenuItem>
            // })

            primary = <Stack direction={'column'}>
                <TextField
                    label='Name' name='name'
                    required
                    value={newUser.name}
                    onChange={(event) => changeData(event.target)}/>
                <TextField
                    label='Email' name='email'
                    required
                    value={newUser.email}
                    onChange={(event) => changeData(event.target)}/>
                <MultipleSelect
                    data={Object.values(props.user_data.role)}
                    values={newUser.role_id==='' ? [] : newUser.role_id}
                    setValues={(newRoles) => changeData({name:'role_id',value: newRoles})}/>
                <TextField
                    label='Password' name="password"
                    type="password"
                    required
                    value={newUser.password}
                    onChange={(event) => changeData(event.target)}/>
                <TextField
                    label='Repeat password' name="repeat_password"
                    type="password"
                    required
                    value={newUser.repeat_password}
                    onChange={(event) => changeData(event.target)}/>
            </Stack>
            secondary = null
        } else {
            primary = item.name
            let roles = item.role_id.map(id => id ? props.user_data.role[id]?.title : null)
            secondary = item.email + ' \t - Roles: ' + roles.join(', ')
        }
        return {primary: primary, secondary: secondary}
    }

    return (
            props.loadStatus.user.loaded ? <OptionList
            data={props.user_data.user}
            title={props.title}
            type='user' level='user'
            // selected={props.currentReport}
            admin={props.admin}
            valid={valid}
            newItem={newUser}
            setNewItem={setNewUser}
            buildItem={buildItem}
            changeItem={()=>{}}
        /> : null
    )
}

const mapStateToProps = state => {
    return {
        user_data: state.data.user,

        // currentReport: state.data.currentReport,
        loadStatus: state.data.loadStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
    // getData: (type,level,filter,setOption) => dispatch(actions.getData('fetch',type,level,filter,[],[],setOption,false)),
    // writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
    // clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    // changeReport: (id) => dispatch(actions.changeReport(id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListUsers);