import React, {Component} from 'react';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Button from '../Button/Button';

// import vars from '../../../../shared/styles/base.module.scss';
import classes from './Input.module.scss';

class InputFile extends Component {

    state = {
        crop: {...this.props.elementConfig.crop},
        loaded: false,
        croppedImageUrl: null,
        ext: null,
        fileType: null,
    }

    componentDidMount = () => {
        // console.log('did mount')
        this.cropChanged = false
        this.maxwidth = this.props.elementConfig.maxwidth;
        this.getFileTypeFromURL(this.props.value)
        this.setState({loaded: true})
    }

    componentDidUpdate = (prevProps,prevState) => {
        // console.log('did update')
        if (prevProps.value!==this.props.value) {
            this.getFileTypeFromURL(this.props.value)
            // this.setState({crop: {...this.props.elementConfig.crop}})
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
        // console.log('on image loaded')
        this.setState({crop: {...this.props.elementConfig.crop}})
        this.getFileTypeFromURL(this.props.value)
        return false;
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // console.log('on crop change')
        this.setState({ crop: percentCrop });
    };

    onCropComplete = (crop, percentCrop) => {
        // correcting for crop areas out of image boundaries
        let nowCrop = {...percentCrop}
        // console.log('on crop complete')
        nowCrop.x = Math.min(Math.max(0,percentCrop.x),100)
        nowCrop.y = Math.min(Math.max(0,percentCrop.y),100)
        this.cropChanged = true
        this.makeClientCrop(nowCrop)
        this.setState({ crop: nowCrop });
    };


    async createFile(url) {
        let response = await fetch(url);
        let data = await response.blob();
        let metadata = {
            type: this.state.fileType
        };
        const file = new File([data], "cropped_" + Math.random().toString(30).substr(2, 36) +"."+this.state.ext, metadata);
        return file
    }

    stringToHash(string) {
        var hash = 0;

        if (string.length === 0) return hash;

        for (let i = 0; i < string.length; i++) {
            const char = string.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash;
    }

    async makeClientCrop(crop) {
        if (this.imageRef) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.stringToHash(this.imageRef) + '.'+this.state.ext,
                this.state.fileType
            );
            this.setState({ croppedImageUrl });

            let file = await this.createFile(croppedImageUrl);
            this.props.changed({target: {files: [file], updateCrop: true}},this.props.id)
        }
    }

    getCroppedImg(image, crop, fileName, fileType) {

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const x0 = image.naturalWidth
        const y0 = image.naturalHeight

        const sx = x0 * crop.x/100
        const sy = y0 * crop.y/100

        const sWidth = x0 * crop.width/100
        const sHeight = y0 * crop.height/100

        if (this.maxwidth) {
            const scale = this.maxwidth/sWidth;
            canvas.width = Math.min(sWidth,this.maxwidth);
            canvas.height = scale>1 ? sHeight : sHeight*scale;
        } else {
            canvas.width = sWidth;
            canvas.height = sHeight;
        }

        // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            sx, sy,
            sWidth, sHeight,
            0, 0,
            canvas.width, canvas.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                fileType,
                1
            );
        });
    }

    getFileTypeFromURL = (url) => {
        fetch(url)
            .then(res => res.blob())
            .then(blob => {
                const ext = blob.type.split('/')[1]
                this.setState({fileType: blob.type, ext: ext})
            })
    }

    toggleTrashFile = (key) => {
        this.props.changed({target: {trash: key}})
    }

    render() {

        const addClass = [];
        let dataButton = <Button type="trash" clicked={() => this.toggleTrashFile(this.props.id)}/>

        // if (this.props.trashFiles[this.props.id]) {
        //     addClass.push(classes.TrashFile);
        //     dataButton = <Button type="restore" clicked={() => this.toggleTrashFile(this.props.id)}/>
        // }

        const {crop, loaded, croppedImageUrl} = this.state
        let display = <p>{this.props.name}</p>

        if (this.state.filetype !== 'application/pdf') {
            display = <>
                    <h3>Bild</h3>
                    <img className={addClass.join(' ')} src={this.props.value} alt="Hochgeladenes Bild"/>
                </>

            if (this.props.elementConfig.crop) {
                display = <>
                    <h3>Bild</h3>
                    <ReactCrop
                        src={this.props.value}
                        crop={crop}
                        keepSelection
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}>
                    </ReactCrop>
                    </>
            }
        }

        return (
            <div className={classes.InputFile}>
                {loaded && this.state.fileType && (
                    <>
                        <div className={classes.imagePreview}>
                            {display}
                            {dataButton}
                        </div>
                        {this.props.elementConfig.crop && this.cropChanged ?
                            <div className={classes.imagePreview}>
                                <h3>Bearbeiteter Ausschnitt</h3>
                                <img src={croppedImageUrl} alt="zugeschnittenes Bild"/>
                            </div> : null}
                    </>
                )}
            </div>
        // }
        )
    }
}

export default InputFile;
