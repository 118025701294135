import * as actionTypes from './actionTypes';

export const addSnackbar = (severity, message) => {
    return {
        type: actionTypes.ADD_SNACK,
        severity: severity,
        message: message
    }
}

export const closeSnackbar = () => {
    return {
        type: actionTypes.CLOSE_SNACK,
    }
}