export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    }
};

export const checkValidity = (value,rules) => {
    let isValid = true;
    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }
    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    return isValid
}

export const filterObject = (obj, field, fieldVal) => {
    let result = {}, key;

    for (key in obj) {
        if (obj.hasOwnProperty(key) && obj[key][field]===fieldVal) {
            result[key] = obj[key];
        }
    }

    return result;
};

export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export const buildGRI = (dimension=null,topic=null,subtopic=null,disclosure=null) => {
    // console.log(dimension,topic,subtopic,disclosure)

    let dim = dimension*100;
    if (topic) {
        dim += parseInt(topic)
    }
    let GRI = dim.toString()
    GRI += subtopic ? "."+subtopic : ''
    GRI += disclosure ?? ''

    // console.log(GRI)

    return GRI
}

const quantifyGRI = (gri) => {

    let q = (gri.dimension*100 + parseInt(gri.topic) + parseInt(gri.subtopic))*100
    q += parseInt(gri.disclosure,36) - 9
    return q;

}

export const sortGRI = (gri1,gri2) => {
    return quantifyGRI(gri1) - quantifyGRI(gri2)
}