import React, {useContext, useState} from 'react';
import {
    Box,
    ButtonGroup, CircularProgress,
    IconButton,
    InputAdornment, MenuItem, Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import * as actions from "../../../store/actions";
import DeleteIcon from '@mui/icons-material/Delete'
import {connect} from "react-redux";
import Card from "../../UI/Card/Card";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import classes from "../../Measures/Measures.module.scss";
import {DisplayContext} from "../../../shared/DisplayContext";

function DataCard(props) {

    const context = useContext(DisplayContext)

    const [data, setData] = useState({value: props.value, description: props.description})
    const [changed, setChanged] = useState(false)

    const [showMore,setShowMore] = useState(false)
    const [addingNew,setAddingNew] = useState(false)

    const addData = () => {
        setAddingNew(true)

        props.writeToDB('update','measures','data',null,{
            set_id: props.currentSet,
            measure_id: props.measure_id,
            reportEntity_id: props.currentReportEntity,
        },() => {
            props.getData('measures','data',{measure_id: props.measure_id, set_id: props.currentSet, ...context.additionalFilter},[],'add',setAddingNew(false))
            props.getData('measures','timeful',{measure_id: props.measure_id, set_id: props.currentSet},[],'add',props.onChange)
        })
    }

    const removeData = () => {
        // console.log(props)
        props.writeToDB('remove', 'measures', 'data', {'id': props.id}, {},
            () => {
                props.clearDataEntry('measures','data',props.id)
                props.getData('measures','timeful',{measure_id: props.measure_id, set_id: props.currentSet},[],'add',props.onChange)
        })
    }

    const changeHandler = (id,event,callSave=false) => {
        setData({...data, [id]:event.target.value})
        setChanged(true)
        if (callSave) {
            saveData()
        }
    }

    const saveData = () => {
        // only save to database when at least one change was registered
        if (changed) {
            // console.log('saving')
            let where = props.id ? {id: props.id} : null
            props.writeToDB('update','measures','data',where,{
                value: data.value,
                description: data.description
            },() => {
                props.getData('measures','data',{measure_id: props.measure_id, set_id: props.currentSet, ...context.additionalFilter},[],'add',
                    () => props.getData('measures','timeful',{measure_id: props.measure_id, set_id: props.currentSet},[],'add',() => {
                        setChanged(false)
                        props.onChange()
                    })
                )
            })
        }
    }

    let titleEl
    let bodyEl = null
    let actionEl

    let styles = {
        boxSizing: 'border-box',
        margin: '0.5rem 0.25rem',
        padding: '0.5rem',
        bgcolor: '#fff',
        width: props.new ? '10rem' : props.isMobile ? '100%' : '45%',
    }

    if (props.new) {

        titleEl = 'Add data'

        actionEl = <ButtonGroup sx={{margin:'auto'}}>
            <IconButton onClick={addData}>
                {addingNew ? <CircularProgress color="secondary" /> : <AddIcon/>}
            </IconButton>
        </ButtonGroup>
    } else {
        if (context.editRights) {
            titleEl = props.isTracked && (props.type==='yes / no' ? <Select
                id="value1" label="Value"
                value={data.value ?? 0}
                // defaultValue={data.value ?? 1}
                // open={openMenu}
                // onBlur={setOpenMenu(false)}
                variant='standard'
                onChange={(event) => changeHandler('value',event,true)}
                onBlur={saveData}
            >
                <MenuItem key='no' value={0} sx={{width: '3rem'}}>no</MenuItem>
                <MenuItem key='yes' value={1} sx={{width: '3rem'}}>yes</MenuItem>
            </Select> : <TextField
                id='value'
                label='Provide a value'
                variant='standard' sx={{width: '10rem'}}
                value={data.value ?? 0}
                type='number'
                InputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    startAdornment: props.type==='text' ? <InputAdornment position='start'>%</InputAdornment> : (props.unit!=='None' && <InputAdornment position='start'>{props.unit}</InputAdornment>)
                }}
                onChange={(event) => changeHandler('value',event)}
                onBlur={saveData}
            />)

            bodyEl = <>
                {props.isTracked && <Box display='flex' alignItems='center' onClick={() => setShowMore(!showMore)}>
                    <Typography variant='caption'>{showMore ? 'Hide' : 'Provide background info'}</Typography>
                    <IconButton >
                        <ArrowDropDownIcon className={showMore ? classes.rotateIcon : null}/>
                    </IconButton>
                </Box>}
                {(showMore || !props.isTracked) && <TextField
                    id='description'
                    label={'Background info'}
                    variant='standard' multiline rows={4} fullWidth
                    value={data.description ?? ''}
                    onChange={(event) => changeHandler('description',event)}
                    onBlur={saveData}
                />}
                {context.editRights && <ButtonGroup sx={{position: 'absolute', top: '0.5rem', right: '0.5rem'}}>
                    <IconButton onClick={removeData}>
                        <DeleteIcon/>
                    </IconButton>
                </ButtonGroup>}
            </>

            actionEl = props.reportEntity_id ? <Stack direction='row' alignItems='center' gap={1}>
                <AssignmentIcon color='active'/>
                <Typography variant='caption'>{props.reports_data.entity[props.reportEntity_id]?.title}</Typography>
            </Stack> : null
        } else {
            // titleEl = null
            bodyEl = <Typography variant={'body1'}>{props.description}</Typography>
            styles = {
                boxSizing: 'border-box',
                margin: '0.2rem 0.2rem',
                padding: '0',
                bgcolor: '#fff',
                width: '100%',
            }
        }
    }

    return <Card
        title={titleEl}
        body={bodyEl}
        action={actionEl}
        styles={styles}
    />
}

const mapStateToProps = state => {
    return {
        reports_data: state.data.reports,
        isMobile: state.data.isMobile,

        currentSet: state.data.currentMeasureSet,
        currentReportEntity: state.data.currentReportEntity,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(DataCard);