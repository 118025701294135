import React, {useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import Layout from './hoc/Layout/Layout';
import Contact from './containers/Contact/Contact';
import About from './containers/About/About';
import Inspire from './containers/Inspire/Inspire';
import MonitoringWheel from './containers/MonitoringWheel/MonitoringWheel';
import AdminContainer from "./containers/Admin/AdminContainer";
import * as actions from "./store/actions";
import {connect} from "react-redux";
import Login from "./containers/Admin/Login/Login";

function App(props) {

    const [loaded,setLoaded] = useState(false)

    useEffect(() => {
        // loading general data on site mounting
        props.getData('sdg','goal',{})
        props.getData('measures','unit',{})
        props.getData('measures','type',{})
        props.getData('measures','process',{})

        props.getData('measures','set',{},[],[],() => setLoaded(true))

        props.getData('gri','dimension',{})
        props.getData('gri','topic',{})
        props.isLoggedIn()
    },[])

    useEffect(() => {
        // set current set after everything has been loaded
        const currentSet = Object.values(props.set_data).reduce((currentSet,el) => el.set_default ? el.id : currentSet,null)
        props.changeSet(currentSet)
    },[loaded])

    useEffect(() => {
        // loading set-specific data whenever set is changed
        props.getData('measures','timeful',{'set_id':props.currentSet},['timeless'],['dimension','set_id'])
        props.getData('measures','timeful',{'set_id':props.currentSet},['timeless'],['dimension','topic','set_id'])
        props.getData('measures','timeful',{'set_id':props.currentSet},['timeless'],['dimension','topic','subtopic','set_id'])

        props.getData('measures','data',{'set_id':props.currentSet},['timeless'],['measure_id','set_id'])
        props.getData('measures','data',{'set_id':props.currentSet},['timeless'],['dimension','topic','subtopic','set_id'])
        props.getData('gri','stats',{'set_id': props.currentSet})
    },[props.currentSet])


    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    <Route path="/" exact component={MonitoringWheel}/>
                    <Route path="/getinspired" component={Inspire}/>
                    <Route path="/about" component={About}/>
                    <Route path="/contact" component={Contact}/>
                    {props.isAuth && <Route path="/admin" component={AdminContainer}/>}
                    <Route path={"/login"} exact component={Login}/>
                    <Redirect to="/"/>
                </Switch>
            </Layout>
        </BrowserRouter>
    );
}

const mapStateToProps = state => {
    return {
        currentSet: state.data.currentMeasureSet,
        set_data: state.data.measures.set,

        isAuth: state.auth.isAuth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (type,level,filter,links,groupBy,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,'replace',true,callbackFcn)),
        changeSet: (id) => dispatch(actions.changeMeasureSet(id)),

        isLoggedIn: () => dispatch(actions.isLoggedIn()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);