import React, {Component} from 'react';
import ListReports from "../../../components/Report/ListReports";
import {
    Typography,
    Stack, Box
} from "@mui/material";
import {connect} from "react-redux";

import {compose} from "redux";
import {withRouter} from "react-router-dom";
import EntityCard from "../../../components/Report/Entity/EntityCard";

class ManageReports extends Component {

    render() {

        let reportEntities = []
        if (this.props.currentReport && this.props.loadStatus.entity?.loaded) {
            reportEntities = Object.values(this.props.reports_data?.entity).map(e => {
                if (e.report_id==this.props.currentReport) return <EntityCard key={e.id} {...e}/>
            })
        }

        if (this.props.currentReport && this.props.loadStatus.report?.loaded) {
            reportEntities.push(<EntityCard key='add' new={true}/>)
        }

        return (
            <div style={{paddingBottom: '1rem'}}>
                <Typography variant='h2'>Manage reports</Typography>
                <Box sx={{width: '50%', padding:'1rem'}}>
                    <ListReports title='Choose a report blueprint' admin={false}/>
                </Box>
                {this.props.currentReport ? <>
                    <Typography variant='h6'>{'Existing reports from "' + this.props.reports_data.report[this.props.currentReport]?.title + '" blueprint'}</Typography>
                    <Stack direction="row" margin='auto' alignItems="start">
                        {reportEntities}
                    </Stack>
                </> : null}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        reports_data: state.data.reports,
        measures_data: state.data.measures,
        GRI_data: state.data.gri,

        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,
        loadStatus: state.data.loadStatus,
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         // getData: (type,level,filter,groupBy,setOption) => dispatch(actions.getData('fetch',type,level,filter,groupBy,setOption)),
//         // writeToDB: (option,type,level,id,data) => dispatch(actions.writeToDB(option,type,level,id,data)),
//         // changeReportEntity: (id) => dispatch(actions.changeReportEntity(id))
//     }
// }

export default compose(
    withRouter,
    connect(mapStateToProps)
)(ManageReports);