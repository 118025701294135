import React, {Component} from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Badge, Stack, Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import ChangeMeasure from "./Timeful/ChangeMeasure";
import DataList from "../Report/DataList";
import ProgressBar from "../GRIDisplay/ProgressBar/ProgressBar";
import {DisplayContext} from "../../shared/DisplayContext";
import MeasureHeader from "./MeasureHeader";
import EditableText from "../UI/Input/EditableText";
import classes from "./Measures.module.scss";

class Measure extends Component {

    state = {
        changeMeasure: false,
        showData: false,
        rerender: false,
        editing: false
    }

    toggleShowData = () => {
        this.props.getData('measures','data',{measure_id:this.props.id, set_id:this.props.currentSet, ...this.context.additionalFilter},[],[],'add',
            () => this.setState({showData: !this.state.showData})
        )
    }

    // shouldComponentUpdate(nextProps,nextState) {
    //     // console.log('should update?')
    //     if (nextState.rerender || this.state.rerender ||
    //         nextProps.currentReport!==this.props.currentReport ||
    //         nextProps.currentSet!==this.props.currentSet) {
    //         // console.log('yes!')
    //         return true
    //     } else {
    //         // console.log('no!')
    //         return false
    //     }
    // }

    // componentDidMount() {
    //     if (!this.context.editRights) {
    //         console.log('loading data')
    //
    //     }
    // }

    updateMeasure = (data,callback) => {

        let where = {'id': this.props.id, 'set_id': this.props.currentSet}
        // const data = {[id]:value}

        this.props.writeToDB('update','measures','timeless',where,data,
            () => this.props.getData('measures','timeful',{'measure_id': this.props.id, 'set_id': this.props.currentSet},[],[],'add',callback)
        )
    }

    changeHandler = () => {
        this.props.getData('reports','entity',{},[],[],'replace')
        let filter = {'set_id':this.props.currentSet}
        if (this.props.currentReportEntity) {
            filter['reportEntity_id'] = this.props.currentReportEntity
        }
        this.props.getData('measures','data',filter,['timeless'],['measure_id','set_id'])
        this.props.getData('gri','subtopic',{
            ...filter,
            dimension: this.props.dimension,
            topic: this.props.topic,
            subtopic: this.props.subtopic},
            [],[],'add')
        this.props.getData('measures','data',{set_id:this.props.currentSet, reportEntity_id: this.props.currentReportEntity},['timeless'],['measure_id','set_id',])
        this.props.getData('measures','data',{set_id:this.props.currentSet, reportEntity_id: this.props.currentReportEntity},['timeless'],['dimension','topic','subtopic','set_id',])
        // console.log('something changed!')
    }


    render() {

        // console.log('[Measure.js] rerendering')
        // console.log(this.props)

        let isTracked = false, mayAdd = false
        if (!this.props.new) {
            mayAdd = !(this.props.measures_data.process[this.props.process_id].title==="Unique value" && this.props.count_data.data['measure_id'][this.props.id]>=1)
            isTracked = this.props.progress_tracked===1
        }

        let header = <MeasureHeader
            {...this.props}
            editRights={this.context.editRights}
            adminRights={this.context.adminRights}
            isTracked={isTracked}
            showData={this.state.showData}
            toggleShowData={this.toggleShowData}
            toggleChangeParameter={() => this.setState({changeMeasure: !this.state.changeMeasure})}
            onChange={this.changeHandler}
            updateMeasure={this.updateMeasure}
        />

        if (this.context.editRights) {

            let content = null
            if (!this.props.new && this.context.editRights) {

                let dataListEl = null
                if (this.state.showData) {
                    const data = this.props.measures_data.data ? Object.values(this.props.measures_data.data).filter(d => d.measure_id===this.props.id) : null
                    dataListEl = <DataList
                        data={data}
                        measure_id={this.props.id}
                        unit={this.props.measures_data.unit[this.props.unit_id]?.title}
                        type={this.props.measures_data.type[this.props.type_id]?.title}
                        isTracked={isTracked}
                        adding={this.context.editRights && mayAdd}
                        onChange={this.changeHandler}
                    />
                }

                const change = this.state.changeMeasure && <ChangeMeasure element={this.props} onChange={this.changeHandler}/>
                // {/*sx={{position: 'absolute', bottom: '0.5rem', right: '0.5rem'}}*/}
                content = <>
                    {/*<Tooltip title={this.props.remarks ?? ''}>*/}
                    {/*    <InfoIcon />*/}
                    {/*</Tooltip>*/}
                    {(this.props.remarks || this.context.adminRights) ? <Stack direction={'row'} gap={1}>
                        <InfoIcon />
                        <EditableText label={'Remarks'} editable={this.context.adminRights} variant='caption' tag={'remarks'} text={this.props.remarks} onSave={(data,callback) => this.updateMeasure(data,callback)} />
                        {/*<Typography variant={'caption'}>{this.props.remarks}</Typography>*/}
                    </Stack> : null}
                    {isTracked && <ProgressBar {...this.props} value={this.props.total_value} unit={this.props.measures_data.unit[this.props.unit_id]?.title} thumbs/>}
                    {dataListEl}
                    {change}
                </>
            }

            return (
                <Badge sx={{width:'100%',top:'0.5rem'}} badgeContent={this.props.count_data.data['measure_id'][this.props.id]} color="primary">
                    <Accordion
                        key='measure'
                        sx={{backgroundColor: '#eee', width:'100%',margin:'4px 0'}}
                        expanded={this.state.changeMeasure || this.state.showData}
                        TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            {header}
                        </AccordionSummary>
                        <AccordionDetails>
                            {content}
                        </AccordionDetails>
                    </Accordion>
                </Badge>
            )
        } else {
            const data = this.props.measures_data.data ? Object.values(this.props.measures_data.data).filter(d => d.measure_id===this.props.id) : null

            return <div className={classes.MeasureGraph} key={this.props.id}>
                {header}
                {isTracked ? <ProgressBar
                    {...this.props}
                    unit={this.props.measures_data.unit[this.props.unit_id]?.title}
                    breakPoint={60}
                    thumbs/> : <DataList
                    data={data}
                    measure_id={this.props.id}
                    unit={this.props.measures_data.unit[this.props.unit_id]?.title}
                    type={this.props.measures_data.type[this.props.type_id]?.title}
                    isTracked={isTracked}
                    adding={this.context.editRights && mayAdd}
                    onChange={this.changeHandler}
                />}
            </div>
        }

    }
}
Measure.contextType = DisplayContext;
    // {/*<Box header={header} content={content} divClasses={divClasses} active={this.state.changeMeasure || this.state.showData}/>*/}

const mapStateToProps = state => {
    return {
        measures_data: state.data.measures,
        reportData: state.data.reports.report,
        count_data: state.data.counts,

        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,
        currentReportEntity: state.data.currentReportEntity,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,groupBy,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Measure);