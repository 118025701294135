import React from 'react';
import DataCard from "./Entity/DataCard";
import {Box} from "@mui/material";

function DataList(props) {
    // console.log('[rerender] DataList.js')

    const dataEls = Object.values(props.data).map(d => <DataCard
        key={d.id}
        unit={props.unit}
        type={props.type}
        onChange={props.onChange}
        isTracked={props.isTracked}
        {...d}
    />)

    if (props.adding) {
        dataEls.push(<DataCard
            key={'add'}
            new={true}
            unit={props.unit}
            type={props.type}
            measure_id={props.measure_id}
            onChange={props.onChange}
        />)
    }

    return <Box sx={{display:'flex', flexWrap: 'wrap', alignItems:'start'}}>
        {dataEls}
    </Box>

}

export default DataList;