import React, {Component, useEffect} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Route, Switch} from 'react-router-dom';
import GRI from "../GRI/GRI";
import ManageReportEntities from "./Reports/ManageReports";
import ManageMeasures from "./Reports/ManageMeasures";
import SubmitReport from "./Reports/SubmitReport";
import AdminInspire from "./Inspire/Inspire";
import ManageOptions from "./Options/ManageOptions";
import Users from "./Users/Users";

function AdminContainer(props) {

    useEffect(() => {

        props.getData('user','role',{})
        props.getData('reports','target_group',{})
        props.getData('reports','frequency',{})
        props.getData('reports','report',{},[],[],() => {props.changeReport(parseInt(Object.keys(props.reports_data.report)[0]))})

        props.getData('CO2','parameter',{})

        return () => {
            props.resetData([
                {'reports':'report'},
                {'reports':'entity'},
                {'reports':'target_group'},
                {'reports':'frequency'},
                {'CO2':'parameter'},
            ])
        }
    },[])

    useEffect(() => {
        props.getData('reports','entity',{set_id: props.currentSet, report_id: props.currentReport})
    },[props.currentSet,props.currentReport])

    const roles = props.theUser ? props.theUser.role_id.map(id => parseInt(id)) : []
    const superAdmin = roles.includes(1)

    return (
        <Switch>
            {/*<Route path="/admin/gri" exact component={GRI} />*/}
            <Route path="/admin/reports/manageReportEntities" exact component={ManageReportEntities} />
            <Route path="/admin/reports/submit" component={SubmitReport} />
            <Route path="/admin/inspire" component={AdminInspire} />
            {superAdmin && <Route path="/admin/manageMeasures" exact component={ManageMeasures} />}
            {superAdmin && <Route path="/admin/manageOptions" exact component={ManageOptions} />}
            {superAdmin && <Route path="/admin/users" component={Users} />}
        </Switch>
    );
}

const mapStateToProps = state => {
    return {
        theUser: state.auth.theUser,
        reports_data: state.data.reports,

        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,groupBy,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,'replace',false,callbackFcn)),
        changeReport: (id) => dispatch(actions.changeReport(id)),
        resetData: (keys) => dispatch(actions.resetData(keys)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AdminContainer);