import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    Box,
    FormControlLabel, Stack,
    Switch,
    Typography
} from "@mui/material";

import * as actions from '../../../store/actions';
import GRIFilter from "../../GRI/GRIFilter";
import Spinner from "../../../components/UI/Spinner/Spinner";
import TopicList from "../../../components/GRIDisplay/Topic/TopicList";
import {DisplayContext} from "../../../shared/DisplayContext";

import classes from "../Admin.module.scss";

class ManageMeasures extends Component {

    state = {
        filterReport: false,
        displayContext: {
            editRights: true,
            adminRights: true,
            filterIt: false,
            additionalFilter: null,
            showDisclosures: true,
            selectGoal: (dimension,topic,subtopic,disclosure='',goal_id,goal_target='',callback) => {
                this.props.writeToDB('update','gri','link_SDG',{
                    'dimension':dimension,
                    'topic':topic,
                    'subtopic': subtopic,
                    'disclosure': disclosure,
                    'SDG_goal':goal_id,
                    'SDG_target':goal_target
                    },{},callback)
                // console.log('selected measure '+dimension + ' ' + topic + ' ' + subtopic + ' ' + disclosure)
                // console.log('selected goal '+goal_id)
            }
        }
    }

    componentDidMount() {
        this.loadOnSetChange()
    }

    toggleReportFilter = () => {
        this.setState({filterReport: !this.state.filterReport})
        // this.loadData()
    }

    loadOnFilterChange = (setId=null,callbackFcn=()=>{}) => {

        // console.log('load on filter change')
        // console.log(this.props.griFilter)

        const newSet = setId ?? this.props.currentSet
        let filter = {set_id: newSet}

        let go = false
        for (let level in this.props.GRI_data) {

            if (!this.props.griFilter[level] || this.props.griFilter[level].length===0) break
            go = true

            filter[level] = this.props.griFilter[level]
        }

        if (go) {
            if (this.state.filterReport) {
                filter['report_id'] = this.props.currentReport
            }
            // console.log('load data',filter)
            this.props.getData('gri','subtopic',{set_id: newSet, ...filter},[],[],'replace',true)
            // this.props.getData('gri','disclosure',{set_id: newSet, ...filter},[],[],'replace',true)


            this.props.getData('measures', 'timeful', filter, [], [], 'replace', false,
                () => callbackFcn())
        } else {
            callbackFcn()
        }
    }

    loadOnSetChange = (setId=null) => {

        // console.log('load on set change')

        const newSet = setId ?? this.props.currentSet

        this.props.resetData([
            {'measures':'timeful'},
            {'measures':'data'}
        ])

        // const ids = Object.values(this.props.measures_data.data).map(d => d.measure_id)

        this.props.getData('measures','timeful',{set_id: newSet},['timeless'],['dimension','set_id'],'replace',false)
        this.props.getData('measures','timeful',{set_id: newSet},['timeless'],['dimension','topic','set_id'],'replace',false)
        this.props.getData('measures','timeful',{set_id: newSet},['timeless'],['dimension','topic','subtopic','set_id'],'replace',false)

        this.props.getData('measures','data',{set_id: newSet},['timeless'],['measure_id','set_id',],'replace',false)

        this.loadOnFilterChange(newSet)
    }

    // componentDidUpdate = () => {
    //
    //     if (!this.props.loadStatus.timeful?.loaded && !this.props.loadStatus.timeful?.loading) {
    //         // console.log('[ManageReport.js] did update')
    //         this.loadData()
    //     }
    // }

    render() {

        // console.log('[rerendering] manageReports.js')

        let reports = Object.values(this.props.reports_data.report).map(s => ({
            value: s.id,
            displayValue: s.title
        }))

        reports.unshift({
            value: 0,
            displayValue: 'Please choose'
        })

        let topics = this.props.loadStatus?.timeful.loading ? <Spinner/> : null

        if (!this.props.loadStatus.topic?.loading && this.props.loadStatus.topic?.loaded) {
        topics = <DisplayContext.Provider value={this.state.displayContext}>
            <TopicList filterIt={false} />
        </DisplayContext.Provider>
        }

        return (
            <>
                {/*<Button onClick={this.props.importDatabase}>Import DB</Button>*/}
                <Typography variant={this.props.isMobile ? 'h4' : 'h2'}>Manage measures</Typography>
                {/*<Typography variant="body1">Here comes the different types of reports</Typography>*/}
                {/*<Stack direction='row' className={classes.Choices}>*/}
                {/*    <Box className={classes.Choices__Set}>*/}
                {/*        <ListSets title='Choose a set' admin={true} onChange={this.loadOnSetChange}/>*/}
                {/*    </Box>*/}
                {/*    <Box className={classes.Choices__Report}>*/}
                {/*        <ListReports title='Choose report blueprint' admin={true}/>*/}
                {/*    </Box>*/}
                {/*</Stack>*/}
                <div style={{display: 'flex'}}>
                    {/*<Typography variant='h6'>Display all</Typography>*/}
                    {/*<FormControlLabel*/}
                    {/*    control={<Switch/>}*/}
                    {/*    label={'Filter by current report'}*/}
                    {/*    sx={{marginLeft: '1rem'}}*/}
                    {/*    checked={this.state.filterReport}*/}
                    {/*    disabled={this.props.currentReport===null}*/}
                    {/*    onChange={this.toggleReportFilter}*/}
                    {/*/>*/}
                    <GRIFilter filterType='measure' onChange={() => this.loadOnFilterChange(this.props.currentSet)}/>
                    <FormControlLabel
                        control={<Switch/>}
                        label={'Show original disclosure texts'}
                        sx={{marginLeft: '1rem'}}
                        checked={this.state.displayContext.showDisclosures}
                        onChange={() => this.setState({displayContext: {...this.state.displayContext, showDisclosures: !this.state.displayContext.showDisclosures}})}
                    />
                </div>
                {topics}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        reports_data: state.data.reports,
        measures_data: state.data.measures,
        GRI_data: state.data.gri,
        griFilter: state.data.griFilter,

        isMobile: state.data.isMobile,

        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,
        loadStatus: state.data.loadStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,groupBy,setOption,setLoading,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,setOption,setLoading,callbackFcn)),
        writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
        changeSet: (id) => dispatch(actions.changeMeasureSet(id)),
        resetData: (keys) => dispatch(actions.resetData(keys)),
        // importDatabase: () => dispatch(actions.importDatabase())
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ManageMeasures);