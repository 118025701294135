import axios from '../../axios';
import * as actionTypes from './actionTypes';
import * as status from './status';

export const checkMobile = (width) => {
    return {
        type: actionTypes.CHECK_MOBILE,
        width: width,
    }
}

export const changeMeasureSet = (id) => {
    return {
        type: actionTypes.CHANGE_MEASURE_SET,
        id: id,
    }
}

export const changeReport = (id) => {
    return {
        type: actionTypes.CHANGE_REPORT,
        id: id,
    }
}

export const changeReportEntity = (id) => {
    return {
        type: actionTypes.CHANGE_REPORTENTITY,
        id: id,
    }
}

export const selectGRI = (level, id=null,reset=false) => {
    return {
        type: actionTypes.SELECT_GRI,
        level: level,
        id: id,
        reset: reset
    }
}

export const setData = (option,type,level,data) => {
    return {
        type: actionTypes.SET_DATA,
        option: option,
        data_type: type,
        level: level,
        data: data,
    }
}


export const purgeData = (option,type,level,id) => {
    return {
        type: actionTypes.SET_DATA,
        data_type: type,
        level: level,
        id: id,
    }
}

export const clearDataEntry = (type,level,id) => {
    return {
        type: actionTypes.CLEAR_DATA_ENTRY,
        data_type: type,
        level: level,
        id: id
    }
}

export const setStats = (data) => {
    return {
        type: actionTypes.SET_STATS,
        data: data,
    }
}

export const setCounts = (level,groupBy,data) => {
    return {
        type: actionTypes.SET_COUNTS,
        level: level,
        groupBy: groupBy,
        data: data,
    }
}

export const setLoadStatus = (type,level,loading,loaded) => {
    return {
        type: actionTypes.SET_LOAD_STATUS,
        data_type: type,
        level: level,
        loading: loading,
        loaded: loaded,
    }
}

export const resetData = (keys=[]) => {
    return {
        type: actionTypes.RESET_DATA,
        keys: keys
    }
}

export const getData = (option,type,level,filter={},links=[],groupBy=[],setOption='replace',setLoading=true,callbackFcn=()=>{}) => {

    return (dispatch) => {

        if (setLoading) {
            dispatch(setLoadStatus(type,level,true,false))
        }

        // console.log(option,type,level)
        // console.log(JSON.stringify(filter))
        // console.log(JSON.stringify(links))
        // console.log(JSON.stringify(groupBy))

        const reqData = new FormData();
        reqData.append('type', type);
        reqData.append('option', option);
        reqData.append('level', level);
        reqData.append('filter', JSON.stringify(filter))
        reqData.append('links', JSON.stringify(links))
        reqData.append('groupBy', JSON.stringify(groupBy))

        const loginToken = localStorage.getItem('loginToken');
        reqData.append('Authorization', 'Bearer ' + loginToken)

        axios.post('apiHandler.php', reqData)
            .then(response => {
                // console.log('getData:',response)
                if (level==='stats') {
                    dispatch(setStats(response.data))
                } else if (groupBy.length>0) {
                // } else if (['counts_measures','counts_data'].includes(level)) {
                    dispatch(setCounts(level,groupBy,response.data))
                } else {
                    if (response.data.length>0) {
                        dispatch(setData(setOption,type,level,response.data))
                    } else {
                        dispatch(clearDataEntry(type,level,filter['id']))
                    }
                    // console.log('loading done',type,level)
                    callbackFcn()
                    dispatch(setLoadStatus(type,level,false,true))
                }
                // dispatch(status.addSnackbar('success','Data successfully fetched from database'))
            })
            .catch(error => {
                dispatch(status.addSnackbar('error','Data could not be fetched from database'))
                console.log('Something went wrong while getting data from DB: ',error)
                dispatch(setLoadStatus(type,level,false,false))
            })
    }
}

export const writeToDB = (option,type,level,where,data,callbackFcn=()=>{}) => {

    return (dispatch) => {
        // console.log('updating',option,type,level)
        // console.log(JSON.stringify(where))
        // console.log(JSON.stringify(data))
        const reqData = new FormData();
        reqData.append('type', type);
        reqData.append('option', option);
        reqData.append('level', level);
        reqData.append('where', JSON.stringify(where))

        if (data['file']) {
            reqData.append('file', data['file']);
            delete data['file']
        }
        reqData.append('data', JSON.stringify(data))

        const loginToken = localStorage.getItem('loginToken');
        reqData.append('Authorization', 'Bearer ' + loginToken)

        axios.post('adminHandler.php', reqData)
            .then(response => {
                // console.log(response)
                dispatch(status.addSnackbar('success','Data successfully written to database'))
                callbackFcn()
            })
            .catch(error => {
                dispatch(status.addSnackbar('error','Error while writing to database'))
                console.log('Something went wrong while writing data to DB: ',error)
            })
    }
}

// export const importDatabase = () => {
//
//     return () => {
//         // console.log('updating',option,type,level)
//         // console.log(JSON.stringify(where))
//         // console.log(JSON.stringify(data))
//
//         axios.post('importDatabase.php')
//             .then(response => {
//                 console.log(response)
//                 // dispatch(status.addSnackbar('success','Data successfully written to database'))
//                 // callbackFcn()
//             })
//             .catch(error => {
//                 // dispatch(status.addSnackbar('error','Error while writing to database'))
//                 console.log('Something went wrong while writing data to DB: ',error)
//             })
//     }
// }