import React, {Component} from 'react';
import {
    Box, IconButton, Button,
    Divider,
    Stack,
    Stepper, Step, StepLabel, StepContent,
    TextField,
    Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TopicList from "../../../components/GRIDisplay/Topic/TopicList";
import PageBack from "../../../components/Navigation/PageBack";
import InspireItem from "../../../components/Inspire/InspireItem";
import {DisplayContext} from "../../../shared/DisplayContext";
import ProgressBar from "../../../components/GRIDisplay/ProgressBar/ProgressBar";


const steps = [
    {
        label: 'General Information',
        description: 'Provide general information about this report'
    },
    {
        label: 'Report questions',
        descriptions: 'Fill in data for a specified set of questions'
    },
    // {
    //     label: 'Best Practices',
    //     descriptions: 'Help others by submitting best practices from the reporting period that helped you in achieving certain goals'
    // }
]

class SubmitReport extends Component {

    state = {
        tab: 'general',
        activeStep: 0,
        editTitle: false,
        values: {
            title: '',
            description: '',
            author: '',
            date: ''
        },
        // additionalFilter: {reportEntity_id: this.props.currentReportEntity},

        context: {
            editRights: true,
            adminRights: false,
            additionalFilter: {report_id: this.props.currentReport, reportEntity_id: this.props.currentReportEntity},
            showDisclosures: false,
            filterIt: true
        }
    }

    componentDidMount() {
        const location = this.props.history.location
        let report = location.search.match(/report=([^&]*)/)[1]
        this.props.changeReport(parseInt(report))

        let entity = location.search.match(/entity=([^&]*)/)[1]
        this.props.changeReportEntity(parseInt(entity))

        this.props.getData('gri','topic',{},[],[],'replace',true)
        this.props.getData('gri','subtopic',{set_id: this.props.currentSet},[],[],'replace',true)

        // this.props.getData('measures','timeful',{set_id: this.props.currentSet, report_id: report},[],[],'replace',false)

        // getting counts
        this.props.getData('measures','timeful',{set_id:this.props.currentSet, report_id: report},['timeless','report'],['dimension','set_id'])
        this.props.getData('measures','timeful',{set_id:this.props.currentSet, report_id: report},['timeless','report'],['dimension','topic','set_id'])
        this.props.getData('measures','timeful',{set_id:this.props.currentSet, report_id: report},['timeless','report'],['dimension','topic','subtopic','set_id'])

        this.props.getData('measures','data',{set_id:this.props.currentSet, reportEntity_id: entity},['timeless'],['measure_id','set_id',])
        this.props.getData('measures','data',{set_id:this.props.currentSet, reportEntity_id: entity},['timeless'],['dimension','topic','subtopic','set_id',])
    }

    componentWillUnmount() {
        this.props.resetData([
            {'measures':'timeful'},
            {'measures':'data'}
        ])
        this.props.changeReportEntity(null)
    }

    handleNext = () => {
        this.setState({activeStep: this.state.activeStep + 1});
    };

    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1});
    };

    handleReset = () => {
        this.setState({activeStep: 0});
    };

    saveGeneralInfo = (event,id) => {
        const data = {[id]: event.target.value}
        const where = {'set_id': this.props.currentSet, 'id': this.props.currentReportEntity}
        this.props.writeToDB('update','reports','entity',where,data,
            () => this.props.getData('reports','entity',where,[],[],'add',false)
        )
    }

    handleChange = (event, newTab) => {
        this.setState({tab: newTab});
    }

    render() {
        let progress = this.props.reports_data.entity?.[this.props.currentReportEntity]?.progress ?? 0
        return (
            <div>
                <PageBack />
                {/*<Typography variant='h2'>{'Submit "'+ this.props.title}</Typography>*/}
                <Stack direction='row' alignItems={'center'} gap={3}>
                    {this.state.editTitle ? <TextField
                        label='Report title'
                        variant='standard' fullWidth
                        defaultValue={this.props.reports_data?.entity[this.props.currentReportEntity].title}
                        onBlur={(event) => {
                            this.saveGeneralInfo(event,'title')
                            this.setState({editTitle: false})
                        }}
                    /> : <Typography variant='h3'>{this.props.reports_data.entity?.[this.props.currentReportEntity]?.title}</Typography>}
                    <IconButton onClick={() => this.setState({editTitle: !this.state.editTitle})}>
                        {this.state.editTitle ? <DoNotDisturbIcon /> : <EditIcon /> }
                    </IconButton>
                </Stack>
                <Typography variant='h6'>{this.props.reports_data.report[this.props.currentReport]?.title}</Typography>
                <ProgressBar progress={progress} title={'Report ' + progress.toFixed(2) + '% finished'}/>

                <Divider variant="middle" sx={{margin:'2rem 0'}}/>

                <Box sx={{display: 'flex'}}>

                    <Box sx={{ width: '30%', typography: 'body1' }}>
                        {/*<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                        {/*    <Tabs value={this.state.tab} onChange={this.handleChange} aria-label="lab API tabs example">*/}
                        {/*        <Tab label="General Information" value='general' />*/}
                        {/*        <Tab label="Measure questions" value='questions' />*/}
                        {/*        <Tab label="Best practices" value='bestPractices' />*/}
                        {/*        <Tab label="Other" value='other' />*/}
                        {/*    </Tabs>*/}
                        {/*</Box>*/}
                        <Stepper activeStep={this.state.activeStep} nonLinear orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        optional={
                                            index === 2 ? (
                                                <Typography variant="caption">Last step</Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={this.handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={this.handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    <Box sx={{width:'70%'}}>
                        {this.state.activeStep===0 && <>
                            <TextField
                                label='Provide a description'
                                variant='standard' fullWidth multiline rows={4}
                                defaultValue={this.props.reports_data.entity?.[this.props.currentReportEntity]?.description}
                                onBlur={(event) => this.saveGeneralInfo(event,'description')}
                            />
                            <TextField variant='standard' label="Author"
                               defaultValue={this.props.reports_data.entity?.[this.props.currentReportEntity]?.author}
                               onBlur={(event) => this.saveGeneralInfo(event,'author')}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Report published at"
                                    variant='standard'
                                    // value={value}
                                    onChange={() => {}}
                                    renderInput={(params) => <TextField variant='standard' {...params} />}
                                />
                            </LocalizationProvider>
                        </>}

                        {this.state.activeStep===1 && <DisplayContext.Provider value={this.state.context}>
                            <TopicList />
                        </DisplayContext.Provider>}

                        {this.state.activeStep===2 && <>
                            <Box sx={{width:'100%'}}>
                                <Typography variant='h6'>Add a project</Typography>
                                <InspireItem new={true} type='project'/>
                            </Box>

                            <Box sx={{width:'100%'}}>
                                <Typography variant='h6'>Add a policy</Typography>
                                <InspireItem new={true} type='policies'/>
                            </Box>
                        </>}
                    </Box>
                </Box>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        reports_data: state.data.reports,
        measures_data: state.data.measures,
        GRI_data: state.data.gri,

        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,
        currentReportEntity: state.data.currentReportEntity,
        loadStatus: state.data.loadStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,groupBy,setOption,setLoading,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,setOption,setLoading,callbackFcn)),
        writeToDB: (option,type,level,where,data,callbackFcn) => dispatch(actions.writeToDB(option,type,level,where,data,callbackFcn)),
        changeReport: (id) => dispatch(actions.changeReport(id)),
        changeReportEntity: (id) => dispatch(actions.changeReportEntity(id)),
        resetData: (keys) => dispatch(actions.resetData(keys))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SubmitReport);