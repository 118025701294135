import React from 'react';
import Disclosure from "./Disclosure";
import {Box} from "@mui/material";

function DisclosureList(props) {

    // console.log('[DisclosureList.js] rerendering')
    const disclosureEls = Object.values(props.data).map(t =>
        <Disclosure
            key={t.id}
            {...t}
            onChange={props.onChange}
        />
    )

    return <Box>{disclosureEls}</Box>
}

export default DisclosureList;