import React, {useRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import InputFile from './InputFile';
// import Button from '../Button/Button';

import {Editor} from '@tinymce/tinymce-react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import classes from './Input.module.scss';
import {Button, Checkbox, FormControl, FormControlLabel, Select, Switch, Tooltip} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import AddIcon from "@mui/icons-material/Add";


function Input(props) {

    const [pwVisible, setPWVisible] = useState(false)
    const editorRef = useRef(null);

    let inputElement = null;
    let additionalElement = null;
    const inputClasses = [classes.inputElement];

    const requiredMark = <span style={{color: 'red'}}>*</span>


    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push(classes.Invalid)
    }
    if (props.hidden) {
        return null
    }

    switch (props.elementType) {
        case ('input'):
            inputElement = <>
                <TextField
                    id={props.id}
                    label={props.label + (props.shouldValidate?.required ? requiredMark : '')}
                    variant='standard'
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}/>
                {/*<FormHelperText id="standard-weight-helper-text">{props.label + (props.shouldValidate?.required ? requiredMark : '')}</FormHelperText>*/}
            </>
            break;
        case ('textarea'):
            inputElement = <TextField
                    id={props.id}
                    label={props.label + (props.shouldValidate?.required ? requiredMark : '')}
                    multiline
                    fullWidth
                    {...props.elementConfig}
                    value={props.value}
                    onChange={props.changed}/>
            break;
        case ('richtext'):
            let value = '';
            try {
                value = props.value.getCurrentContent().getPlainText();
            } catch {
                value = props.value;
            }
            inputClasses.push(classes.RichText)
            inputElement = <div>
                <Editor
                    {...props.elementConfig}
                    onInit={(evt, editor) => editorRef.current = editor}
                    value={value}
                    apiKey='61bsxdu77hb0r540nxfj7j6i26c162yetepdpfq4gc2vbznh'
                    init={{
                        height: props.height ?? 200,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                            // 'link | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                    onEditorChange={props.changed}
                />
            </div>;
            break;
        case ('checkbox'):
            inputClasses.push(classes.Checkbox)
            inputElement = <FormControlLabel control={
                <Checkbox
                    id={props.id}
                    // className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    checked={props.checked}
                    // value={props.value}
                    onChange={props.changed}/>
            } label={props.label}
            />
            break;
        case ('switch'):
            inputElement = <FormControlLabel
                control={<Switch />}
                label={props.label}
                checked={props.checked}
                onChange={props.changed}
            />
            break;
        case ('select'):
            // inputClasses.push(classes.Dropdown)
            inputElement = <>
                <InputLabel
                    id={props.id}>{props.label}{props.shouldValidate?.required ? requiredMark : null}</InputLabel>
                <Select
                    labelId={props.id}
                    id={props.id}
                    {...props.elementConfig}
                    disabled={props.elementConfig?.disabled}
                    value={props.value}
                    onChange={props.changed}>
                    {props.elementConfig.options?.map(option => {
                            return <MenuItem key={option.value} value={option.value}>{option.displayValue}</MenuItem>
                        }
                    )}
                </Select>
            </>
            break;
        case ('multiselect'):
            inputClasses.push(classes.Dropdown)
            // console.log(props.value)
            inputElement = <div>
                <select
                    id={props.id}
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    disabled={props.elementConfig.disabled}
                    value={0}
                    onChange={props.changed}>
                    {props.elementConfig.options.map(option => {
                            return <option key={option.value} value={option.value}
                                           disabled={props.value.includes(option.value)}>{option.displayValue}</option>
                        }
                    )}
                </select>
                <div className={classes.multiSelectResults__div}>
                    {props.value.map(val => {
                        return <Tippy key={val}
                                      content={val}>
                            <div className={classes.multiSelectResults__element}>
                                <p><i className="fas fa-times"
                                      onClick={props.changed} dataid={val}></i>{val}</p>
                            </div>
                        </Tippy>
                    })}
                </div>
            </div>
            break;
        case ('color'):
            inputElement = <input
                id={props.id}
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}/>;
            break;
        case ('date'):
            inputElement = <div>
                <DatePicker
                    id={props.id}
                    {...props.elementConfig}
                    onChange={props.changed}
                    selected={props.value}
                    isClearable
                    dateFormat='dd.MM.yyyy'/>
            </div>;
            break;
        case ('datetime'):
            inputElement = <div>
                <DatePicker
                    id={props.id}
                    onChange={props.changed}
                    selected={props.value}
                    showTimeSelect
                    isClearable
                    dateFormat="dd.MM.yyyy HH:mm"/>
            </div>;
            break;
        case ('file'):
            inputElement = <Button variant="contained" component="label" color="primary">
                    {" "}
                    <AddIcon/> Upload a file
                    <input type="file" hidden onChange={props.changed} />
                </Button>
                {/*<input*/}
                {/*    id={props.id}*/}
                {/*    className={classes.fileInput}*/}
                {/*    {...props.elementConfig}*/}
                {/*    onChange={props.changed}*/}
                {/*    accept={props.elementConfig.filetypes}/>*/}
                {/*<Button type="upload"/>*/}
            // </>
            additionalElement = props.value && <InputFile {...props}/>
            break;
        case ('password'):
            const icon = pwVisible ? "far fa-eye-slash" : "far fa-eye";
            inputElement = <div className={classes.PassWrapper}>
                <input
                    id={props.id}
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                    type={pwVisible ? 'text' : 'password'}
                    value={props.value}
                    onChange={props.changed}
                    onBlur={() => setPWVisible(false)}/>
                <i className={icon} onClick={(e) => {
                    setPWVisible(!pwVisible);
                    e.stopPropagation()
                }}></i>
            </div>
            break;
        default:
            inputElement = <input
                id={props.id}
                // className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}/>;
            break;
    }

        // <div key={props.id} >
        // </div>
    if (props.tooltip) {
        inputElement = <Tooltip title={props.tooltip}>
            {inputElement}
        </Tooltip>
    }
    return (
        <FormControl variant='standard' sx={{m: 1, ...props.elementConfig?.style}} fullWidth={props.fullWidth}>
            {inputElement}
            {additionalElement}
        </FormControl>
    )
    //     </label>
    // <label htmlFor={props.id} className={classes.Label__post}>
    // </label>
}

export default Input;
