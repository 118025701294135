import React from 'react';
import {connect} from 'react-redux';

import * as actions from '../../../store/actions';
import {
    Box,
    Checkbox, FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    MenuItem, Stack,
    Select, TextField, Tooltip,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const ChangeMeasure = (props) => {

    // ['type','unit','process','baseline','target']
    // console.log('[ChangeMeasure.js] rerendering')
    // const initialized = useRef(false)
    // const [values,setValues] = useState({
    //     description: props.element.description,
    //     remarks: props.element.remarks,
    //     type_id: props.element.type_id,
    //     unit_id: props.element.unit_id,
    //     process_id: props.element.process_id,
    //     baseline: props.element.baseline,
    //     target: props.element.target,
    //     weight: props.element.weight,
    //     loaded: true
    // })

    // const [changed,setChanged] = useState(false)

    // useEffect(() => {
    //     const updatedValues = {}
    //     Object.keys(values).forEach(key => {
    //         updatedValues[key] = props.element[key] ?? 1
    //     })
    //     updatedValues['loaded'] = true
    //     setValues(updatedValues)
    // }, [props]);

    const isNumber = () => {
        const type = props.element.type_id ? props.measure_types[parseInt(props.element.type_id)].type : null
        return type==='number'
    }

    const updateMeasure = (id,value) => {

        // console.log(id,value)

        let where = {'id': props.element.measure_id, 'set_id': props.currentSet}
        const data = {[id]:value}

        props.writeToDB('update','measures','timeless',where,data,
            () => props.getData('measures','timeful',{'measure_id': props.element.measure_id, 'set_id': props.currentSet},[],'add',props.onChange)
        )
    }

    // console.log(props.CO2)
    // let isCO2 = true
    // let CO2_parameter = isCO2 && <FormControl sx={{width: '10rem'}}>
    //     <InputLabel id="selectGRI_label">Select fitting parameter</InputLabel>
    //     <Select
    //         id="selectPara" labelId="selectPara_label" label="GRI"
    //         value=''
    //         variant='standard'
    //         onChange={(event) => console.log('updating')} // updateMeasure('parameter',event.target.value)
    //     >
    //         {Object.values(props.CO2_data.parameter).map(t => (
    //             <MenuItem key={t.id} value={t.id} sx={{width: '10rem'}}>{t.title}</MenuItem>
    //         ))}
    //     </Select>
    // </FormControl>


    let editContent = null

    const type = props.element.type_id ? props.measure_types[parseInt(props.element.type_id)].title : null

    // if (values.loaded) {
    editContent = <div style={{position: 'relative'}}>
    <hr/>
        <h3>Editing measure</h3>

        {/*<TextField*/}
        {/*    variant='standard'*/}
        {/*    label='Description' defaultValue={props.element.description}*/}
        {/*    fullWidth multiline rows={2}*/}
        {/*    onBlur={(event) => updateMeasure('description',event.target.value)}*/}
        {/*/>*/}

        {/*<TextField*/}
        {/*    variant='standard'*/}
        {/*    label='Remarks' defaultValue={props.element.remarks}*/}
        {/*    fullWidth multiline rows={2}*/}
        {/*    onBlur={(event) => updateMeasure('remarks',event.target.value)}*/}
        {/*/>*/}

        <Stack direction='row' gap={2} sx={{margin:'1rem 0'}}>
            {props.element.progress_tracked===1 && <>
            {type==='yes / no' ? <Select
                id="value1" label="Value"
                value={props.element.baseline}
                // defaultValue={data.value ?? 1}
                // open={openMenu}
                // onBlur={setOpenMenu(false)}
                variant='standard'
                onChange={(event) => updateMeasure('baseline',event.target.value)}
            >
                <MenuItem key='no' value={0} sx={{width: '3rem'}}>no</MenuItem>
                <MenuItem key='yes' value={1} sx={{width: '3rem'}}>yes</MenuItem>
            </Select> : <TextField
                    variant='standard'
                    label='Baseline' defaultValue={props.element.baseline} type={isNumber() ? 'number' : null}
                    sx={{width: '10rem'}}
                    onBlur={(event) => updateMeasure('baseline',event.target.value)}
                    InputProps={(type==='number' && props.element.unit_id>1) ? {'endAdornment': <InputAdornment position='end'>{props.measure_data.unit[props.element.unit_id]?.title}</InputAdornment>} : null}
                />}
            {type==='yes / no' ? <Select
                id="value1" label="Value"
                value={props.element.target}
                // defaultValue={data.value ?? 1}
                // open={openMenu}
                // onBlur={setOpenMenu(false)}
                variant='standard'
                onChange={(event) => updateMeasure('target',event.target.value)}
            >
                <MenuItem key='no' value={0} sx={{width: '3rem'}}>no</MenuItem>
                <MenuItem key='yes' value={1} sx={{width: '3rem'}}>yes</MenuItem>
            </Select> : <TextField
                    variant='standard'
                    label='Target' defaultValue={props.element.target} type={isNumber() ? 'number' : null}
                    sx={{width: '10rem'}}
                    onBlur={(event) => updateMeasure('target',event.target.value)}
                    InputProps={(type==='number' && props.element.unit_id>1) ? {'endAdornment': <InputAdornment position='end'>{props.measure_data.unit[props.element.unit_id]?.title}</InputAdornment>} : null}
                />}
            </>}
        </Stack>
        <Stack direction='row' gap={2} sx={{margin:'1rem 0'}}>
            <FormControl sx={{width: '6rem'}}>
                <InputLabel id="selectType">TypeElement</InputLabel>
                <Select
                    id="selectType" labelId="selectType_label" label="Type"
                    defaultValue={props.element.type_id}
                    variant='standard'
                    onChange={(event) => updateMeasure('type_id',event.target.value)}
                >
                    {Object.values(props.measure_types).map(t => (
                        <MenuItem key={t.id} value={t.id} sx={{width: '7rem'}}>{t.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {type==='number' && <FormControl sx={{width: '5rem'}}>
                <InputLabel id="selectType">Unit</InputLabel>
                <Select
                    id="selectType" labelId="selectType_label" label="TypeElement"
                    defaultValue={props.element.unit_id}
                    variant='standard'
                    onChange={(event) => updateMeasure('unit_id',event.target.value)}
                >
                    {Object.values(props.measure_units).map(t => (
                        <MenuItem key={t.id} value={t.id} sx={{width: '7rem'}}>{t.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>}
            {props.element.progress_tracked===1 && <FormControl sx={{width: '10rem'}}>
                <InputLabel id="selectType">Process</InputLabel>
                <Select
                    id="selectType" labelId="selectType_label" label="TypeElement"
                    defaultValue={props.element.process_id}
                    // open={openMenu}
                    // onBlur={setOpenMenu(false)}
                    variant='standard'
                    onChange={(event) => updateMeasure('process_id',event.target.value)}
                >
                    {Object.values(props.measure_processes).map(t => (
                        <MenuItem key={t.id} value={t.id} sx={{width: '7rem'}}>{t.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>}
            {props.element.progress_tracked===1 && <TextField
                variant='standard'
                label='Weight' defaultValue={props.element.weight} type='number' step='0.01'
                sx={{width: '5rem' }}
                onBlur={(event) => updateMeasure('weight',event.target.value)}
            />}
        </Stack>
        <Box sx={{display:'flex', alignItems:'center', margin: '1rem 0'}}>
            <FormControlLabel control={<Checkbox
                variant='standard'
                // value={''}
                defaultChecked={!!props.element.CO2}
                onChange={(event) => {
                    // console.log(event.target.checked)
                    updateMeasure('CO2',event.target.checked)
                }}
            />} label="affects CO2" />
            {props.element.CO2===1 && <FormControl sx={{width: '20rem'}}>
                <InputLabel id="selectGRI_label">Select fitting parameter</InputLabel>
                <Select
                    id="selectPara" labelId="selectPara_label" label="CO2 parameter"
                    defaultValue={props.element.CO2param_id}
                    // value={props.element.CO2param_id}
                    variant='standard'
                    onChange={(event) => updateMeasure('CO2param_id',event.target.value)}
                >
                    {Object.values(props.CO2_data.parameter).map(t => (
                        <MenuItem key={t.id} value={t.id} sx={{width: '10rem', display:'flex', alignItems:'bottom'}}><Tooltip title={t.description}><InfoIcon color='active'/></Tooltip>   {t.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>}
        </Box>
    </div>
    // }

    return editContent

}

const mapStateToProps = (state) => {
    return {
        currentSet: state.data.currentMeasureSet,
        measure_data: state.data.measures,
        GRI_data: state.data.gri,
        CO2_data: state.data.CO2,

        selectedSubtopics: state.data.selectedSubtopics,

        measure_types: state.data.measures.type,
        measure_units: state.data.measures.unit,
        measure_processes: state.data.measures.process,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callback) => dispatch(actions.writeToDB(option,type,level,where,data,callback)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ChangeMeasure);
