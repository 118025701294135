import React from 'react';
import {IconButton} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {useHistory} from "react-router-dom";

function PageBack() {
    let history = useHistory();
    return (
        <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon/>
        </IconButton>
    );
}

export default PageBack;