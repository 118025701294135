import React, {useState} from 'react';
import { connect } from 'react-redux';

import classes from './Goals.module.scss'
import {Box, styled, Tooltip} from "@mui/material";
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))
(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const Goals = (props) => {

    const [openChoice,setOpenChoice] = useState(false)

    let goals
    if (!props.goals && !props.select) return null
    else if (props.select) {
        goals = Array.from({length: 17}, (_, i) => i + 1)
    } else {
        goals = props.goals.sort((a,b) => a-b)
    }

    if (!props.loadStatus.goal.loaded)
        return null

    const inputGoals = props.goals.map(goal => parseInt(goal))
    const goalDisplay = goals.map(goal => {
        const offset = -(goal-1)*props.size+'px 0px'
        if (goal) {
            let coverEl = null
            if (props.select && !inputGoals.includes(goal)) {
                coverEl = <div style={{
                    opacity: 0.6,
                    backgroundColor: 'white',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: props.size+'px',
                    zIndex: 10,
                    pointerEvents: 'none'
                }}/>
            }
            return <li key={goal} style={{height: props.size+'px',
            width: props.size+'px'}}>
                <Tooltip
                    key={'goal_'+goal}
                    title={"Goal "+goal+": "+props.goal_data[parseInt(goal)].name}
                    followCursor
                    enterTouchDelay={10}
                >
                    <div style={{position: 'relative', overflow:'hidden'}}>
                        {coverEl}
                        <div
                            onClick={props.onClick ? () => props.onClick(goal) : () => {}}
                            className={classes.GoalElement}
                            style={{
                                position: 'relative',
                                backgroundPosition: offset,
                                height: props.size+'px',
                                width: props.size+'px',
                                backgroundSize: props.size*17+'px '+props.size+'px'
                        }} >
                        </div>

                    </div>
                </Tooltip>
            </li>
        } else {
            return null
        }
    })

    let editEl = null
    if (props.editable) {
        editEl = <Box sx={{width:props.size*6.8 + 'px', boxSizing:'border', border:'2px solid black', borderRadius:'5px'}}>
            <ConnectedGoals goals={props.goals} select size={props.size} onClick={props.onEdit}/>
        </Box>

        return <HtmlTooltip
            title={editEl}
            arrow={true}
            open={openChoice}
            onClick={() => setOpenChoice(!openChoice)}
            >
            <ul className={classes.Goals}>{goalDisplay}</ul>
        </HtmlTooltip>
    } else {
        return <ul className={classes.Goals}>{goalDisplay}</ul>
    }

}

const mapStateToProps = (state) => {
    return {
        goal_data: state.data.sdg.goal,
        loadStatus: state.data.loadStatus,
    }
}

const ConnectedGoals = connect(mapStateToProps)(Goals);
export default ConnectedGoals
