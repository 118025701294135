import React, {Component} from 'react';

import classes from './Modal.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


class Modal extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.show !== this.props.show ||  nextProps.children !== this.props.children;
    }


    render() {
        const closeButton = <IconButton
            sx={{position: 'absolute', 'top': '0.25rem', right: '0.25rem'}}
            onClick={this.props.modalCancelled}>
            <CloseIcon />
        </IconButton>

        const modal = (
            <div
                className={classes.Modal}>
                {this.props.children}
                {closeButton}
            </div>
        )
        return (
            <Aux>
                <Backdrop show={this.props.show} clicked={this.props.modalCancelled} />
                {this.props.show ? modal : null}
            </Aux>
        )
    }
};

export default Modal;
