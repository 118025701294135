import React, {useMemo} from 'react';
import {NavLink, useHistory, useLocation} from 'react-router-dom';

import Dropdown from '../../UI/Dropdown/Dropdown';
import classes from './NavigationItems.module.scss';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import NavigationItem from "./NavigationItem/NavigationItem";

function NavigationItems(props) {

    const navData = {
        about: {
            ddHeader: {
                title: "About",
                href: '/about/',
            },
            ddList: [
                {
                    id: 0,
                    title: "GRI",
                    selected: false,
                    href: 'about#gri',
                    nav: true
                },
                {
                    id: 1,
                    title: "SDG",
                    selected: false,
                    href: 'about#sdg',
                    nav: true
                },
                {
                    id: 2,
                    title: "Why monitoring?",
                    selected: false,
                    href: 'about#monitoring',
                    nav: true,
                },
                {
                    id: 3,
                    title: "What's important?",
                    selected: false,
                }
            ]
        },
        inspire: {
            ddHeader: {
                title: "Get inspired",
                href: '/getinspired/'
            },
            ddList: [
                {
                    id: 0,
                    title: "Projects",
                    href: 'projects',
                    selected: false,
                },
                {
                    id: 1,
                    title: "Policies",
                    href: 'policies',
                    selected: false,
                },
                {
                    id: 2,
                    title: "Funding",
                    href: 'funding',
                    selected: false,
                }
            ]

        },
        admin: {
            ddHeader: {
                title: "Admin",
                href: '/admin/'
            },
            ddList: [
                // {
                //     id: 0,
                //     title: "Input measures",
                //     selected: false,
                //     href: '/admin/gri'
                //     nav: true
                // },
                {
                    id: 0,
                    title: "Submit a report",
                    selected: false,
                    href: 'reports/manageReportEntities'
                },
                {
                    id: 1,
                    title: "Submit policies",
                    selected: false,
                    href: 'inspire/policies',
                    nav: true
                },
                {
                    id: 2,
                    title: "Submit projects",
                    selected: false,
                    href: 'inspire/projects',
                    nav: true
                },
            ]
        }
    }

    let location = useLocation()
    const pathname = location.pathname




    return useMemo(() => {
        if (props.superAdmin) {
            const additionalLinks = [
                {
                    id: 3,
                    title: "Manage measures",
                    selected: false,
                    href: 'manageMeasures',
                    nav: true
                },
                {
                    id: 4,
                    title: "Manage options",
                    selected: false,
                    href: 'manageOptions',
                    nav: true
                },
                {
                    id: 5,
                    title: "Manage Users",
                    selected: false,
                    href: 'users',
                    nav: true
                }]
            navData.admin.ddList.push(...additionalLinks)
        }

        return (
            <ul className={classes.NavigationItems}>
                <li><NavLink
                    to="/" exact
                    activeClassName={classes.active}
                    onClick={props.clicked}
                >Monitoring</NavLink></li>
                <NavigationItem
                    header={navData.inspire.ddHeader}
                    list={navData.inspire.ddList}
                    clicked={props.clicked}
                    pathName={pathname}
                    // addClasses={pathname.startsWith(navData.inspire.ddHeader.href) ? classes.active : null}
                    />
                <NavigationItem
                    header={navData.about.ddHeader}
                    list={navData.about.ddList}
                    clicked={props.clicked}
                    pathName={pathname}
                    // addClasses={pathname.startsWith(navData.about.ddHeader.href) ? classes.active : null}
                />
                <li><NavLink
                    to="/contact"
                    activeClassName={classes.active}
                    onClick={props.clicked}>Contact us</NavLink></li>
                {props.admin && <NavigationItem
                    header={navData.admin.ddHeader}
                    list={navData.admin.ddList}
                    clicked={props.clicked}
                    pathName={pathname}
                    // addClasses={pathname.startsWith(navData.admin.ddHeader.href) ? classes.active : null}
                />}
                {props.isMobile && <NavLink
                    to={props.isAuth ? "/" : "/login"} exact
                    // activeClassName={classes.active}
                    onClick={
                    props.isAuth ? () => {
                        props.logoutUser()
                        props.clicked()
                    } : () => props.clicked()}
                    >{props.isAuth ? 'Logout' : 'Login'}</NavLink>}
            </ul>
        )
    },[pathname,props.admin])
};

const mapStateToProps = (state) => {
    return {
        isMobile: state.data.isMobile,
        isAuth: state.auth.isAuth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => dispatch(actions.logoutUser()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NavigationItems)