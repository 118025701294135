import React from 'react';
import Input from "../../components/UI/Input/Input";
import {connect} from "react-redux";
import * as actions from "../../store/actions";

function GriFilter(props) {

    const selectionChangedHandler = async (e,level_changed) => {

        const id = parseInt(e.target.value)
        await props.selectGRI(level_changed,id)

        props.onChange()

        const index = props.GRI_elements.indexOf(level_changed)
        const indices = props.GRI_elements.slice(index+1,-2)

        const levels = props.GRI_elements.slice(0,index+1)

        let filter = {}
        for (let idx in levels) {
            let level = levels[idx]

            if (level === level_changed && id) {
                filter[level] = [id]
            } else {
                if (!props.griFilter[level]) break
                filter[level] = props.griFilter[level]
            }
        }

        // console.log(props.GRI_elements,indices,levels)

        indices.forEach(lev => {
            // console.log('loading',lev)
            // console.log(filter)
                if (['disclosure', 'subtopic'].includes(lev)) {
                    props.getData('gri', lev, {...filter, set_id: props.currentSet},[],[],'replace')
                } else {
                    props.getData('gri', lev, filter,[''],[],'replace',true)
                }
            }
        )

        // props.setLoadStatus('measures','timeful',false,false)
    }

    const forms = ['dimension'].map(level => {
        // console.log(elements[level])
        const options = Object.entries(props.GRI_data[level]).map(([key,d],) => {
            return {
                value: d[level],
                displayValue: d.GRI + ' - ' + d.description,
            }
        })
        options.unshift({value:'0',displayValue: 'Select to display topics'})

        return <Input
            id={level}
            key={level}
            elementType='select'
            elementConfig={{
                type: 'text',
                placeholder: '',
                options: options,
                style: {
                    minWidth: 120,
                }
            }}

            value={props.griFilter[level]?.length ? props.griFilter[level][0] : 0}
            label={'GRI ' + level}

            changed={(event) => selectionChangedHandler(event,level)}
        />
    })

    return (
        <div style={{display: 'flex', flexFlow: 'row', width: '100%'}}>
            {forms}
        </div>
    );
}

const mapStateToProps = state => {
    return {

        griFilter: state.data.griFilter,
        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,

        GRI_elements: state.data.elements,
        GRI_data: state.data.gri
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getMeasures: (level,published) => dispatch(actions.getMeasures(level,published)),
        selectGRI: (level,id) => dispatch(actions.selectGRI(level,id,true)),
        resetData: () => dispatch(actions.resetData()),
        getData: (type,level,filter,links,groupBy,setOption,setLoading,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,setOption,setLoading,callbackFcn)),
        setLoadStatus: (type,level,loading,loaded) => dispatch(actions.setLoadStatus(type,level,loading,loaded)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GriFilter);