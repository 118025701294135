import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';

import WheelSlice from './WheelSlice/WheelSlice';
import SDGBreakdown from '../Information/SDGBreakdown/SDGBreakdown';
import Modal from '../../components/UI/Modal/Modal';

import classes from './MonitoringWheel.module.scss';
import {Box, Typography} from "@mui/material";


class MonitoringWheel extends Component {

    state = {
        hoverID: null,
        clickedID: null,
        arc: null,
        arc_pos: null,
        loaded: false,
        openModal: false
    }

    mouseOverHandler = (id) => {
        this.setState({hoverID: id})
    }

    clickHandler = (id) => {
        if (this.props.isMobile) {
            if (id===this.state.hoverID) {
                this.mouseOverHandler(null)
                const same = (this.state.clickedID===id)
                this.setState({clickedID: same ? null : id, openModal: !same})
            } else {
                this.mouseOverHandler(id)
            }
        } else {
            const same = (this.state.clickedID===id)
            this.setState({clickedID: same ? null : id, openModal: !same})
        }
    }

    closeModalHandler = () => {
        this.setState({openModal: false})
    }

    render() {

        let slices = null;
        if (this.props.loadStatus.stats.loaded && this.props.loadStatus.goal.loaded) {
            const cumulativeSum = (sum => value => sum += value)(0);
            const priority = Object.values(this.props.SDG_data.goal).reduce(
                (prio,goal) => prio.concat(goal.priority),[])
            priority.unshift(0)
            const prio_total = priority.reduce((sum,el) => sum + el, 0.)
            const arc = priority.map(p => p / prio_total * 360.)
            const arc_pos = priority.map(cumulativeSum).map(p => p / prio_total * 360.)

            slices = Object.values(this.props.SDG_data.goal).map(goal => {
                const offset = 10
                let progress = (goal.progress+offset)/(100+offset) * 100
                return <WheelSlice
                    key={goal.id}
                    options={goal}
                    length={progress+'%'}
                    arc_start={arc_pos[goal.id-1]}
                    arc={arc[goal.id]}
                    isMobile={this.props.isMobile}
                    mouseOverHandler={this.props.isMobile ? ()=>{} : () => this.mouseOverHandler(goal.id)}
                    clickHandler={() => this.clickHandler(goal.id)}
                    clicked={this.state.clickedID===goal.id}
                    hovered={this.state.hoverID===goal.id}
                    openTooltip={this.props.isMobile ? this.state.hoverID===goal.id : null}
                />
            })
        }

        let modalContent = <SDGBreakdown SDG_ID={this.state.clickedID}/>

        return (<>
            <Modal
                show={this.state.openModal}
                modalCancelled={this.closeModalHandler}>
                {modalContent}
            </Modal>
            <Box sx={{position:'relative'}}>
                {/*<Typography variant='h2'>A Sustainable Region</Typography>*/}
                <Box className={classes.WheelContainer} sx={{margin:'2rem auto', position:'relative'}}>
                    <div className={classes.Pie}>
                        {slices}
                    </div>
                </Box>
                <Typography variant='body1'>The wheel above shows a visual approach to the European Scout regions progress in becoming more sustainable in its operations.</Typography>
                <Typography variant='body1'>The circle segments represent separate <a href="/about#sdg">SDGs</a>, each composed of several <a href="/about#gri">disclosures of the GRI</a>. Please explore by clicking on the segments.</Typography>
                <Typography variant='body1'>Their width corresponds to the priority that has been assigned to the according disclosures. The radius of each segment corresponds to the progress that has been made within the reporting period, with 100% at the largest radius.</Typography>
            </Box>
            </>
        )
    }
}
                    // {/*<Box sx={{position:'absolute', bottom:'2rem', right:'2rem'}}>*/}
                    //     {/*<FormControl sx={{width: '5rem'}}>*/}
                    //     {/*    <InputLabel id="selectReferenceSet">Data set</InputLabel>*/}
                    //         {/*<Select*/}
                    //         {/*    id="selectReferenceSet" labelId="selectReferenceSet_label" label="Type"*/}
                    //         {/*    value={this.props.currentSet}*/}
                    //         {/*    variant='standard'*/}
                    //         {/*    onChange={(event) => {*/}
                    //         {/*        this.props.changeSet(event.target.value)*/}
                    //         {/*        this.loadData(event.target.value)*/}
                    //         {/*    }}*/}
                    //         {/*>*/}
                    //         {/*    {Object.values(this.props.set_data).map(t => (*/}
                    //         {/*        <MenuItem key={t.id} value={t.id} sx={{width: '7rem'}}>{t.title}</MenuItem>*/}
                    //         {/*    ))}*/}
                    //         {/*</Select>*/}
                    //     {/*</FormControl>*/}
                    // {/*</Box>*/}

const mapStateToProps = (state) => {
    return {
        isMobile: state.data.isMobile,

        SDG_data: state.data.sdg,
        GRI_data: state.data.gri,
        loadStatus: state.data.loadStatus,
        set_data: state.data.measures.set,

        currentSet: state.data.currentMeasureSet,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetData: () => dispatch(actions.resetData()),
        getData: (type,level,filter,links,groupBy,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,groupBy,setOption,true,callbackFcn)),
        getStats: (type,level,filter,links,groupBy,setOption) => dispatch(actions.getData('fetchStats',type,level,filter,links,groupBy,setOption)),
        changeSet: (id) => dispatch(actions.changeMeasureSet(id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MonitoringWheel);
