import * as actionTypes from '../actions/actionTypes';
import { updateObject, buildGRI } from '../../shared/utility';

const initialState = {
    isMobile: false,
    elements: ['dimension','topic','subtopic','disclosure'],
    sdg: {},
    gri: {
        dimension: {},
        topic: {},
        subtopic: {},
        disclosure: {},
        priority: {}
    },
    measures: {
        set: {},
        unit: {},
        type: {},
        process: {},
        timeless: {},
        timeful: {},
        data: {}
    },
    counts: {
        timeful: {},
        data: {},
    },
    inspire: {
        projects: {},
        policies: {},
    },
    reports: {
        report: {},
        frequency: {},
        target_group: {},
    },
    CO2: {
        parameter: {}
    },
    user: {
        role: {}
    },
    currentMeasureSet: 52,
    currentReport: null,
    currentReportEntity: null,

    griFilter: {},

    loadStatus: {
        goal: {loading: false, loaded: false},

        set: {loading: false, loaded: false},
        unit: {loading: false, loaded: false},
        type: {loading: false, loaded: false},
        process: {loading: false, loaded: false},

        timeless: {loading: false, loaded: false},
        timeful: {loading: false, loaded: false},
        data: {loading: false, loaded: false},

        projects: {loading: false, loaded: false},
        policies: {loading: false, loaded: false},

        dimension: {loading: false, loaded: false},
        topic: {loading: false, loaded: false},
        subtopic: {loading: false, loaded: false},
        disclosure: {loading: false, loaded: false},

        stats: {loading: false, loaded: false},
        user: {loading: false, loaded: false},
    }
}

const checkMobile = (state,action) => {

    const isMobile = action.width < 768
    return updateObject(state,{isMobile: isMobile})
}


const resetData = (state,action) => {

    // console.log('resetting')
    // console.log(action)
    if (action.keys.length===0) {
        return {...initialState, isMobile:state.isMobile}
        // action.keys = Object.keys(state.loadStatus)
    }
    const updatedState = {...state}
    action.keys.forEach((el,i) => {
        let type = Object.keys(el)[0]
        let level = Object.values(el)[0]
        updatedState[type][level] = {}
        updatedState.loadStatus[level] = {}
    })
    return updatedState
    // return initialState
    // return updateObject(state,{
    //     gri: {...initialState.gri},
    //     sdg: {...initialState.sdg},
    //     measures: {...initialState.measures},
    //     inspire: {...initialState.inspire},
    //     loadStatus: {...initialState.loadStatus},
    //     selectedGRI: {},
    // })
}

const changeMeasureSet = (state,action) => {
    return updateObject(state,{currentMeasureSet: action.id})
}

const changeReport = (state,action) => {
    return updateObject(state,{currentReport: action.id})
}

const changeReportEntity = (state,action) => {
    return updateObject(state,{currentReportEntity: action.id})
}

const selectGRI = (state,action) => {


    // if (action.reset) {
    //     // console.log('resetting subtopic')
    //     return updateObject(state,{griFilter: {}})
    // }

    const updatedFilterLevel = action.reset ? [] : [...state.griFilter[action.level]]


    // let idList = Object.values({...state.selectedSubtopics})
    if (action.id) {
        const index = updatedFilterLevel.indexOf(action.id)
        if (index>-1) {
            updatedFilterLevel.splice(index, 1);
        } else {
            updatedFilterLevel.push(action.id)
        }
    }

    const newFilter = updateObject(state.griFilter,{[action.level]: updatedFilterLevel})

    return updateObject(state,{griFilter: newFilter})
}

const setStats = (state,action) => {

    // console.log('set stats',action.data)
    const updatedGoals = {...state.sdg.goal}
    Object.values(updatedGoals).forEach(goal => {
        updatedGoals[goal.id].priority = action.data['priority'][goal.id]
        updatedGoals[goal.id].progress = action.data['progress'][goal.id]
    })

    const updatedData = updateObject(state.sdg,{goal:updatedGoals})
    // console.log(action.data!==null)
    const updatedLoadLevel = {
        loading: false,
        loaded: action.data!==null
    }
    const updatedLoadStatus = updateObject(state.loadStatus,{stats:updatedLoadLevel})

    return updateObject(state,{sdg: updatedData, loadStatus: updatedLoadStatus})
}

const setCounts = (state,action) => {

    let group = null;
    switch (action.level) {
        case 'timeful':
            state.elements.forEach(el => {
                if (action.groupBy.includes(el)) {
                    group = el
                }
            })
            break;
        case 'data':
            state.elements.forEach(el => {
                if (action.groupBy.includes(el)) {
                    group = el
                }
            })
            if (!group)
                group = 'measure_id'
            // console.log(action.groupBy)
            // console.log(group)
            break;
        default:
            console.log('nope')
    }
    let newCount = {}
    let gri, griName

    Object.entries(action.data).forEach(([key,val],) => {
        if (state.elements.includes(group)) {

            // switch (action.level) {
            //     case 'timeful':
            gri = []
            state.elements.forEach(el => {
                if (Object.keys(val).includes(el)) gri.push(val[el])
            })
            griName = buildGRI(...gri)
            if (griName.length >= 3) newCount[griName] = action.level==='data' ? val['m_count'] : val['m_count']
        }
                // break;
        else {
            // case 'data':
            newCount[val['measure_id']] = val['m_count']
        }
            //     break;
            // default:
            //     console.log('no')
        // }
    })
    const newCountGroup = updateObject(state.counts[action.level],{[group]: newCount})
    const newCountLevel = updateObject(state.counts, {[action.level]: newCountGroup})
    return updateObject(state,{'counts': newCountLevel})

    // const updatedCounts = {}

    // return state
}

const setData = (state,action) => {
    // console.log('setting data',action)
    // const level = action.option==='fetchPriority' ? 'priority' : action.level
    const updatedLevel = action.option==='add' ? {...state[action.data_type][action.level]} : {}

    // let id = 0
    if (action.data.length>0) {
        Object.values(action.data).forEach(d => {
            let id = d.id
            // console.log('setting',id, d)
            if (action.data_type==='gri') {

                if (action.level!=='dimension') {
                    d['GRI'] = buildGRI(d.dimension,d.topic,d.subtopic,d.disclosure)
                } else {
                    d['GRI'] = buildGRI(d.dimension)
                }
            }
            updatedLevel[id] = {}
            Object.entries(d).forEach(([key,el],) => {
                if (key==='GRI') {
                    updatedLevel[id][key] = el
                } else {
                    updatedLevel[id][key] = isNaN(parseFloat(el)) || Array.isArray(el) ? el : parseFloat(el)
                }
            })
        })
    } else {
        console.log('got nothing',action)
    }

    const updatedData = updateObject(state[action.data_type],{[action.level]:updatedLevel})

    // console.log('updating: ',action.data_type,action.level)
    const updatedLoadLevel = {
        loading: false,
        loaded: action.data!==null
    }
    const updatedLoadStatus = updateObject(state.loadStatus,{[action.level]:updatedLoadLevel})

    // if (action.data_type==='measures' && action.level==='set') {
    //     const currentSet = Object.values(updatedLevel).reduce((max,el) => el.set_order>max.order ? {order:el.set_order, id: el.id} : max, {order:0, id:null})
    //     return updateObject(state,{[action.data_type]: updatedData, loadStatus: updatedLoadStatus, currentMeasureSet: currentSet.id})
    // } else {
    return updateObject(state,{[action.data_type]: updatedData, loadStatus: updatedLoadStatus})
    // }
}

const clearDataEntry = (state,action) => {

    // console.log('clearing', action)

    const updatedLevel = {...state[action.data_type][action.level]}
    const ids = Array.isArray(action.id) ? action.id : [action.id]
    ids.forEach(id => {
        delete updatedLevel[id]
    })

    const updatedData = updateObject(state[action.data_type],{[action.level]:updatedLevel})
    return updateObject(state,{[action.data_type]: updatedData})
}

const setLoadStatus = (state,action) => {

    const updatedLoadLevel = {
        loading: action.loading,
        loaded: action.loaded
    }
    const updatedLoadStatus = updateObject(state.loadStatus,{[action.level]:updatedLoadLevel})
    return updateObject(state,{loadStatus: updatedLoadStatus})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_DATA:
            return resetData(state,action);
        case actionTypes.SET_DATA:
            return setData(state,action);
        case actionTypes.CLEAR_DATA_ENTRY:
            return clearDataEntry(state,action);
        case actionTypes.SET_STATS:
            return setStats(state,action);
        case actionTypes.SET_COUNTS:
            return setCounts(state,action);
        case actionTypes.SET_LOAD_STATUS:
            return setLoadStatus(state,action);
        case actionTypes.CHANGE_MEASURE_SET:
            return changeMeasureSet(state,action);
        case actionTypes.CHANGE_REPORT:
            return changeReport(state,action);
        case actionTypes.CHANGE_REPORTENTITY:
            return changeReportEntity(state,action);
        case actionTypes.SELECT_GRI:
            return selectGRI(state,action);
        case actionTypes.CHECK_MOBILE:
            return checkMobile(state,action);
        default:
            return state;
    }
}

export default reducer;
