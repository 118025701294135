import React, {useMemo, useState} from 'react';
import { NavLink } from 'react-router-dom';

import classes from '../NavigationItems.module.scss';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Button} from "@mui/material";

const NavigationItem = (props) => {

    const [expanded, setExpanded] = useState(false)
    return useMemo(() => {
        let header_active = props.pathName.startsWith(props.header.href)
        return <>
            <li>
                <Button
                    id="basic-button"
                    key={props.header.title}
                    aria-controls={expanded ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={expanded ? 'true' : undefined}
                    onClick={() => setExpanded(!expanded)}
                    className={header_active ? classes.active : null}
                    sx={{justifyContent: 'space-between'}}
                >
                    {props.header.title}
                    <KeyboardArrowDownIcon className={expanded ? classes.TurnIcon : null}/>
                </Button>
            </li>
            {
                expanded && props.list.map(l => {
                        let href_total = props.header.href + l.href
                        return <li
                            key={l.id}
                            className={props.pathName===href_total ? classes.active : null}
                        >
                            <NavLink
                                to={{
                                    pathname: href_total
                                }}
                                onClick={props.clicked}
                                className={classes.SubMenu}
                            >{l.title}</NavLink>
                        </li>
                    }
                )
            }
        </>
    },[props.pathName,expanded])
};

export default NavigationItem;
