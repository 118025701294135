import React from 'react';
import {connect} from 'react-redux';

import Box from '../Box/Box';

import Goals from '../Lists/Goals/Goals';
import ProgressBar from './ProgressBar/ProgressBar';
import Ratings from './Ratings/Ratings';

import classes from './SubtopicElement.module.scss';

const SubtopicElement = (props) => {

    let progress = parseFloat(props.element.progress)
    progress = isNaN(progress) ? 0 : progress

    const header = <div className={classes.DisclosureElement__Header}>
        <div style={{width: '5%'}}>
            <Ratings data={props.element}/>
        </div>
        <div style={{width:'60%'}}>
            <p><strong>GRI {props.element.GRI}:</strong> {props.element.description}</p>
        </div>
        <div style={{width:'15%'}}>
            <Goals goals={props.element.SDG_goal} size={props.sdg_size}/>
        </div>
        <div style={{width:'20%'}}>
            <ProgressBar progress={progress}/>
        </div>
    </div>

    return <Box header={header} content={props.children} clicked={() => props.expand()} active={props.active}/>
}

export default connect(null)(SubtopicElement);
