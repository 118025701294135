import React from 'react';
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import CO2_parametersElement from "./CO2_parametersElement";

function Co2ParametersList(props) {

    let data = Object.values(props.CO2_data.parameter)

    let CO2ParaListEl = []
    if (data.length) {
        CO2ParaListEl = data.map(d => <CO2_parametersElement key={d.id} {...d}/>)
    }
    CO2ParaListEl.push(<CO2_parametersElement key='new' new={true}/>)

    return (
        <div>
            {CO2ParaListEl}
        </div>
    );
}


const mapStateToProps = state => {
    return {
        CO2_data: state.data.CO2,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callbackFcn) => dispatch(actions.writeToDB(option,type,level,where,data,callbackFcn)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Co2ParametersList);