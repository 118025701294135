import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {
    ButtonGroup, FormControl,
    IconButton,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";

function Co2ParametersElement(props) {

    const [editing,setEditing] = useState(false)
    const [values,setValues] = useState({
        title: props.title ?? '',
        description: props.description ?? '',
        value: props.value ?? 0,
        unit_id: props.unit_id ?? 1,
    })

    const commitElement = () => {
        props.writeToDB('update', 'CO2', 'parameter', props.new ? {} : {id: props.id}, values,
            () => props.getData('CO2','parameter',{},[],'replace',()=>setEditing(false))
        )
    }

    const removeElement = () => {
        const str = `Are you sure you want to delete this CO2 parameter?`
        if (window.confirm(str)) {
            props.writeToDB('remove', 'CO2','parameter', {'id': props.id}, {},
                () => props.clearDataEntry('CO2','parameter',props.id)
            )
        }
    }

    const titleEl = <Stack direction='row' gap={2}>
        {editing ? <>
            <TextField
                id='setName'
                label={`Specify parameter title`}
                variant='standard'
                error={!values.title}
                value={values.title}
                onChange={(event) => setValues({...values, title: event.target.value})}/>
            <TextField
                id='setValue'
                label={`Specify parameter value`}
                variant='standard'
                error={!values.value}
                value={values.value}
                onChange={(event) => setValues({...values, value: event.target.value})}/>
            <FormControl sx={{width: '8rem'}}>
                <InputLabel id="selectUnit_label">TypeElement</InputLabel>
                <Select
                    id="selectUnit" labelId="selectUnit_label" label="Unit"
                    defaultValue={props.unit_id}
                    variant='standard'
                    onChange={(event) => setValues({...values, unit_id:event.target.value})}
                >
                    {Object.values(props.unit_data).map(t => (
                        <MenuItem key={t.id} value={t.id} sx={{width: '5rem'}}>{t.title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </> : props.new ? <Typography variant='body1'>Add new</Typography> : <Typography variant='body1'>
            <span style={{fontWeight:'700'}}>{props.title}:</span> <span>{props.value} kg CO2e per {props.unit_data[props.unit_id]?.title}</span>
        </Typography>}
    </Stack>

    const contentEl = editing ? <TextField
        id='setDescription'
        label='Description'
        variant='standard' multiline rows={2} fullWidth
        value={values.description}
        onChange={(event) => setValues({...values, description: event.target.value})}/> : <Typography variant='caption'>{props.description}</Typography>

    return (
        <ListItem
            alignItems='flex-start'
            // sx={{maxWidth:'10rem'}}
            secondaryAction={
                <ButtonGroup>
                    {(props.new && !editing) ? null : <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => setEditing(!editing)}
                    >
                        {editing ? <DoDisturbIcon/> : <EditIcon/>}
                    </IconButton>}
                    <IconButton
                        edge="end"
                        aria-label="add_delete"
                        onClick={editing ? commitElement : props.new ? () => setEditing(true) : removeElement}
                    >
                        {editing ?
                            <SaveIcon/> : props.new ? <AddIcon/> : <DeleteIcon/>}
                    </IconButton>
                </ButtonGroup>
            }>
            <ListItemText
                primary={titleEl}
                secondary={contentEl}/>
        </ListItem>
    );
}

const mapStateToProps = state => {
    return {
        unit_data: state.data.measures.unit
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callbackFcn) => dispatch(actions.writeToDB(option,type,level,where,data,callbackFcn)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Co2ParametersElement);