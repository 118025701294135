import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    user: {},
    isAuth: false,
    checkingAuth: false,
    theUser: null,
    errorMsg: '',
}

const setUser = (state,action) => {
    return updateObject(state,{isAuth: true, checkingAuth: false, theUser: action.userData});
}

const checkAuth = (state,action) => {
    return updateObject(state,{isAuth: false, checkingAuth: action.status});
}

const loginFailed = (state,action) => {
    return updateObject(state,{errorMsg: action.message});
}

const logoutUser = (state,action) => {
    localStorage.removeItem('loginToken');
    return updateObject(state,{isAuth: false, theUser: null})
}

const registrationCallback = (state,action) => {
    return updateObject(state,{registrationStatus: action.status, registrationMessage: action.msg});
}

// const sendMail = (state,action) => {
//
// }

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.CHECK_AUTH:
            return checkAuth(state,action);
        case actionTypes.SET_USER:
            return setUser(state,action);
        case actionTypes.LOGIN_FAILED:
            return loginFailed(state,action);
        case actionTypes.LOGOUT:
            return logoutUser(state,action);
        case actionTypes.REGISTRATION_CALLBACK:
            return registrationCallback(state,action);
        default:
            return state;
    }
}

export default reducer;
