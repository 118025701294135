import React, { Component } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';

import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import SubtopicElement from '../../components/GRIDisplay/SubtopicElement';
import ListMeasures from '../../components/Measures/MeasureList';
import * as actions from '../../store/actions';

import classes from './GRI.module.scss';
import {Box, IconButton, Tab, Tabs, Typography} from "@mui/material";
import TabPanel from "../../components/UI/Tabs/TabPanel";
import AddIcon from '@mui/icons-material/Add';
import GRIFilter from "./GRIFilter";


class GRI extends Component {

    state = {
        edit: {
            id: null,
            text: null
        },
        newSetName: '',
        tab: 0,
    }

    getFilter(elements) {
        const filter = {}
        for (let key in elements) {
            const val = elements[key]
            if (val) {
                filter[key] = parseInt(val)
            } else {
                break
            }
        }
        return filter
    }

    openEditor(id) {
        if (id===this.state.edit.id) {
            this.setState({edit: {id: null, text: null}})
        } else {
            this.setState({edit: {id: id, text: this.props.GRI_data.disclosure[id].description}})
        }
    }

    textEditorChange(val) {
        const updatedEdit = {...this.state.edit}
        updatedEdit.text = val
        this.setState({edit:updatedEdit})
    }

    updateDisclosure() {
        const where = {'id':this.state.edit.id}
        const data = {'text': this.state.edit.text}
        this.props.writeToDB('update','gri','disclosure',where,data)
        setTimeout(() => this.props.getData('gri','disclosure',this.getFilter({}),'add'),100)
    }

    toggleDisclosure (id) {
        this.props.setLoading('gri','disclosure')
        this.props.setLoading('measures','timeless')
        this.props.selectGRI('subtopic',id)

        setTimeout(()=>{
            const filter = {
                'dimension':parseInt(this.props.GRI_data.subtopic[id].dimension),
                'topic':parseInt(this.props.GRI_data.subtopic[id].topic),
                'subtopic':parseInt(this.props.GRI_data.subtopic[id].subtopic)
            }
            this.props.getData('gri','disclosure',filter,'add')
        },100)
    }

    toggleMeasures (id) {

        this.props.setLoading('measures','timeless')
        const disc_ids = Object.values(this.props.GRI_data.disclosure).map(d => {
            return d.id
        })
        // console.log(this.props.GRI_data.disclosure[id].id)
        const filter = {'disclosure_id': disc_ids, 'set_id':this.props.currentSet}
        this.props.getData('measures','timeless',filter,'replace')
    }

    addMeasure = (id) => {
        const data = {'disclosure_id': this.props.GRI_data.disclosure[id].id, 'set_id':this.props.currentSet, 'description': 'Measure for disclosure '+this.props.GRI_data.disclosure[id].GRI}
        this.props.writeToDB('update','measures','timeless',{},data)
        setTimeout(()=>{
            const disc_ids = Object.values(this.props.GRI_data.disclosure).map(d => {
                return d.id
            })
            const filter = {'disclosure_id': disc_ids, 'set_id':this.props.currentSet}
            this.props.getData('measures','timeless',filter,'replace')
            // console.log(this.props.GRI_data.disclosure[id].id)

            // const filter = {'disclosure_id': id}
        },100)
    }

    addSet = () => {
        this.props.writeToDB('setUp','measures','set',null,{'name':this.state.newSetName, 'ref_id':this.props.currentSet})
        const filter = {}
        setTimeout(() => this.props.getData('measures','set',filter,'replace'), 200);
    }

    removeSet = () => {

        const str = ['Are you sure you want to delete the current dataset ',this.props.measure_data.set[parseInt(this.props.currentSet)].name,'?'].join('')
        if (window.confirm(str)) {
            const where = {'id': this.props.currentSet}
            this.props.writeToDB('clean','measures','set',where,{})
            const filter = {}
            setTimeout(() => this.props.getData('measures','set',filter,'replace'), 200);
        } else {
            console.log('not deleting')
        }
    }

    componentDidMount() {
        this.props.resetData('gri')

        // const filter = {'published':1}
        const filter = {}
        this.props.getData('sdg','goal',filter,'replace')
        this.props.getStats('gri','subtopic',{},'replace')
        this.props.getData('measures','set',filter,'replace')
        this.props.getData('measures','unit',{},'replace')
        this.props.getData('measures','type',{},'replace')
        this.props.getData('measures','process',{},'replace')
        this.props.getData('gri','dimension',{},'replace')
        this.props.getData('reports','report',{},'replace')
    }

    componentWillUnmount() {
        this.props.resetData()
    }

    render () {

        // const forms = this.props.GRI_elements.map(el => {


        let data = <p>Choose a dimension and topic to display according GRI disclosures</p>

        if (this.props.loadStatus.topic.loading && this.props.loadStatus.subtopic.loading) {
            data = <Spinner />
        } else if (this.props.loadStatus.topic.loaded && this.props.loadStatus.subtopic.loaded && this.props.loadStatus.stats.loaded) {
            data = Object.entries(this.props.GRI_data.subtopic).map(([key,subT],) => {
                let discText = null;
                let subData = null;
                let showMeasures = null;
                let sdg_size = 25;
                if (this.props.selectedGRI.subtopics.includes(key)) {

                    showMeasures = <button className={classes.ToggleTexts} onClick={() => this.toggleMeasures(key)}>Display measures</button>

                    discText = subT.disclosure_text ? <p><em>{subT.disclosure_text}</em></p> : null
                    subData = Object.entries(this.props.GRI_data.disclosure).map(([disckey,disc],) => {
                        if (disc.dimension===subT.dimension
                            && disc.topic===subT.topic
                            && disc.subtopic===subT.subtopic) {

                            let edit = null
                            if (disckey===this.state.edit.id) {
                                edit = <div>
                                    <Input
                                        key='richtext'
                                        elementType='richtext'
                                        elementConfig={{
                                            type: 'text',
                                            placeholder: '',
                                        }}
                                        fullWidth={true}
                                        value={this.state.edit.text}
                                        changed={(val) => this.textEditorChange(val)}/>
                                    <Button type='save' clicked={() => this.updateDisclosure()}/>
                                </div>
                            }

                            let measures = null;
                            if (this.props.loadStatus.measure.loaded) {
                                const thisMeasures = Object.values(this.props.measure_data.timeless).filter(m => (m['disclosure_id']===disc.id))
                                if (thisMeasures.length) {
                                    measures = <ListMeasures
                                        data={thisMeasures}
                                        addMeasure={() => this.addMeasure(disckey)}/>
                                }
                            }

                            let description = DOMPurify.sanitize( disc.description );
                            if (!description.startsWith('<p>')) {
                                description = "<p>" + description + "</p>"
                            }
                            description = <div dangerouslySetInnerHTML={{ __html: description}}></div>

                            return <div key={disckey}>
                                    <div className={classes.DisclosureText}>
                                        <p>{disc.disclosure})</p>
                                        {description}
                                        <p><button className={classes.ToggleTexts} onClick={()=>this.openEditor(disckey)}>Edit <i className="fas fa-caret-down"></i></button></p>
                                    </div>
                                    {edit}
                                    {measures}
                                </div>
                        } else {
                            return null;
                        }
                    })
                }

                const content = <div>
                    {discText}
                    {subData}
                    {showMeasures}
                </div>;

                return <SubtopicElement
                        key={key}
                        element={subT}
                        sdg_size={sdg_size}
                        active={this.props.selectedGRI.subtopics.includes(key)}
                        expand={() => this.toggleDisclosure(key)}>
                        {content}
                    </SubtopicElement>
            })
        }
        const setOptions = Object.values(this.props.measure_data.set).map(s => ({
                value: s.id,
                displayValue: s.name
            })
        )

        return (
            <div>
                <Typography variant='h1'>Measurements</Typography>
                <Typography variant='body1'>This is the admin section. Only the office and volunteer teams working for the region will have access to this. You are able to edit and add entries - please refrain from deleting any entries, or introducing major changes - it will only hinder our progress :(</Typography>
                <hr/>

                {/*<Typography variant='h2'>Data sets</Typography>*/}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={this.state.tab} onChange={(event,new_value) => this.setState({tab:new_value})} aria-label="basic tabs example">
                        <Tab label="Choose data set" id='tab-choose-set' aria-controls='tabpanel-0'/>
                        <Tab label="Create new data set" id='tab-create-set' aria-controls='tabpanel-1'/>
                    </Tabs>
                </Box>
                <TabPanel value={this.state.tab} index={0}>
                    <Input
                        elementType='select'
                        elementConfig={{
                            options: setOptions,
                            style: {
                                width: 120
                            }
                        }}
                        value={this.props.currentSet}
                        label={'Set'}

                        changed={(event) => this.props.changeSet(event.target.value)}/>

                </TabPanel>
                <TabPanel value={this.state.tab} index={1}>
                    <Input
                        id='setName'
                        elementType='input'
                        value={this.state.newSetName}
                        label={'Set name'}

                        changed={(event) => this.setState({'newSetName':event.target.value})}/>
                    <IconButton variant='contained' clicked={() => this.addSet()}>
                        <AddIcon/>
                    </IconButton>
                </TabPanel>

                {this.state.tab === 0 &&
                    <div>
                        <Typography variant='h2'>Disclosures</Typography>
                        <GRIFilter filterType='gri'/>
                        <div>
                            {data}
                        </div>
                    </div>
                }

            </div>
        )
        // <Button type='delete' clicked={() => this.removeSet()}/>Remove set
    }

}

const mapStateToProps = state => {
    return {
        measure_data: state.data.measures,
        currentSet: state.data.currentMeasureSet,
        selectedGRI: state.data.selectedGRI,

        GRI_elements: state.data.elements,
        loadStatus: state.data.loadStatus,
        GRI_data: state.data.gri
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getMeasures: (level,published) => dispatch(actions.getMeasures(level,published)),
        resetData: () => dispatch(actions.resetData()),
        getData: (type,level,filter,setOption) => dispatch(actions.getData('fetch',type,level,filter,setOption)),
        getStats: (type,level,filter,setOption) => dispatch(actions.getData('fetchStats',type,level,filter,setOption)),
        writeToDB: (option,type,level,id,data) => dispatch(actions.writeToDB(option,type,level,id,data)),
        setLoading: (type,level) => dispatch(actions.setLoadStatus(type,level,true,false)),
        changeSet: (id) => dispatch(actions.changeMeasureSet(id)),
        selectGRI: (level,id,reset) => dispatch(actions.selectGRI(level,id,reset))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(GRI);
