import React from 'react';

import classes from './WheelSlice.module.scss'
import {Box, Tooltip, Typography} from "@mui/material";
import Goals from "../../../components/Lists/Goals/Goals";

const wheelSlice = (props) => {

    const classAdd = [classes.Slice];
    if (props.clicked) {
        classAdd.push(classes.Clicked)
    }

    const styleAdd = props.hovered ? '1px dashed grey' : null

    // <Goals goals={props.options.id} size={}/> \
    // const info = "<div><Typography variant='body'>SDG #"+props.options.id+": "+props.options.name+"</Typography></br><Typography variant='body1'>Progress: "+props.options.progress.toFixed(1)+"%,</Typography> <Typography variant='body1'>Priority: "+(100*props.options.priority).toFixed(2)+"%</Typography></div>"

    return (
        <div
            className={classes.Pie}
            style={{
                width: props.length,
                height: props.length,
                border: styleAdd
            }}>
            <Tooltip
                title={<Box sx={{display:'flex', alignItems:'center'}} gap={1}>
                    <Goals goals={[props.options.id]} size='65'/>
                    <Box>
                        <Typography variant='body1'>{'SDG #'+props.options.id+': '+props.options.name}</Typography>
                        <Typography variant='caption'>{'Progress: '+props.options.progress?.toFixed(1)+'%, \n Priority: '+(100*props.options.priority).toFixed(2)+'%'}</Typography>
                        {/*{props.openTooltip && <Button onClick={props.clickHandler}>Detailed info</Button>}*/}
                    </Box>
                </Box>}
                enterTouchDelay={props.isMobile ? 10 : 500}
                followCursor={true}>
                <div
                    className={classAdd.join(' ')}
                    onMouseOver={props.mouseOverHandler}
                    onClick={props.clickHandler}
                    style={{
                        transform: 'rotate('+ props.arc_start + 'deg) skewY(' + (props.arc - 90) + 'deg)',
                        background: props.options.color,
                    }}>
                </div>
            </Tooltip>
        </div>
    )
};

export default wheelSlice;
