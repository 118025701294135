import React, {useState} from 'react';
import {Chip, FormControl, InputLabel, MenuItem, Select, Stack, Tooltip} from "@mui/material";
import {connect} from "react-redux";

function GriChipList(props) {

    const [openMenu,setOpenMenu] = useState(false)

    let chooseGRI = null
    if (props.editRights) {
        chooseGRI = <FormControl sx={{width: '10rem'}}>
            <InputLabel id="selectGRI_label">Select fitting GRI</InputLabel>
            <Select
                id="selectGRI" labelId="selectGRI_label" label="GRI"
                value=''
                open={openMenu}
                onClose={(e) => {
                    if (e.target.value!==0) {
                        setOpenMenu(false)
                    }
                    e.stopPropagation()
                }}
                variant='standard'
                onClick={() => setOpenMenu(true)}
                onChange={(event) => {
                    props.handleChange(event.target.value)
                }}
            >
                {Object.values(props.GRI_data.subtopic).map(st => (
                    <MenuItem key={st.id} disabled={props.values.includes(st.id)} value={st.id} sx={{width: '20rem'}}>{st.GRI + ' - ' + st.description}</MenuItem>
                ))}
            </Select>
        </FormControl>
    }

    const griEl = props.values.map(id => {
        const subT = props.GRI_data.subtopic[id]
        if (id && subT) {
            return <Tooltip key={id} title={subT?.description}>
                <Chip
                    size='small'
                    label={subT?.GRI}
                    onDelete={props.editRights ? () => props.handleChange(id) : null}
                    sx={{marginRight:'0.5rem', marginTop:'0.2rem'}}
                />
            </Tooltip>
        } else {
            return null
        }
    })

    return <>
        {chooseGRI}
        <Stack direction='row' sx={{flexWrap:'wrap', margin:'0.5rem'}}>{griEl}</Stack>
    </>
}


const mapStateToProps = state => {
    return {
        GRI_data: state.data.gri,
    }
}

export default connect(mapStateToProps)(GriChipList);
