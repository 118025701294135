import React, {useState} from 'react';
import {List, Typography} from "@mui/material";
import OptionElement from "./OptionElement";

function OptionList(props) {

    const [editing,setEditing] = useState(null)

    const objectList = Object.values(props.data).map(d => {
        return <OptionElement
            key={d.id}
            item={d}
            type={props.type} level={props.level}
            selected={props.selected===d.id}
            editing={editing===d.id}
            editItem={setEditing}
            newItem={props.newItem}
            setNewItem={props.setNewItem}
            admin={props.admin}
            valid={props.valid}
            buildItem={props.buildItem}
            changeItem={props.changeItem}
        />
    })

    // if super admin, ability to add!
    if (props.admin) {
        objectList.push(
            <OptionElement
                key="new"
                new={true}
                item={{id: "new"}}
                type={props.type} level={props.level}
                editing={editing==="new"}
                editItem={setEditing}
                newItem={props.newItem}
                setNewItem={props.setNewItem}
                admin={props.admin}
                valid={props.valid}
                buildItem={props.buildItem}
                changeItem={()=>{}}
            />
        )
    }

    return (
        <List>
            {props.title && <Typography variant='h4'>{props.title}</Typography>}
            {objectList}
        </List>
    );
}

export default OptionList;