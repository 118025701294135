import React, {useState} from 'react';
import TextField from "@mui/material/TextField";
import {ButtonGroup, IconButton, ListItem, ListItemText, Typography} from "@mui/material";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";

function OptionElement(props) {

    const [editing,setEditing] = useState(false)
    const [values,setValues] = useState({
        title: props.title ?? '',
        description: props.description
    })

    const commitElement = () => {
        props.writeToDB('update', props.type, props.level, props.new ? {} : {id: props.id}, values,
            () => props.getData(props.type,props.level,{},[],'replace',()=>setEditing(false))
        )
    }

    const removeElement = () => {
        const str = `Are you sure you want to delete this ${props.type} ${props.level}?`
        if (window.confirm(str)) {
            props.writeToDB('remove', props.type, props.level, {'id': props.id}, {},
                () => props.clearDataEntry(props.type,props.levels,props.id)
            )
        }
    }

    const titleEl = editing ? <TextField
        id='setName'
        label={`Specify ${props.level} title`}
        variant='standard'
        error={!values.title}
        value={values.title}
        onChange={(event) => setValues({title: event.target.value})}/> : <Typography variant='body1'>{props.new ? 'Add new' : props.title}</Typography>

    const contentEl = null

    return (
        <ListItem
            alignItems='flex-start'
            // sx={{maxWidth:'10rem'}}
            secondaryAction={
                <ButtonGroup>
                    {(props.new && !editing) ? null : <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => setEditing(!editing)}
                    >
                        {editing ? <DoDisturbIcon/> : <EditIcon/>}
                    </IconButton>}
                    <IconButton
                        edge="end"
                        aria-label="add_delete"
                        onClick={editing ? commitElement : props.new ? () => setEditing(true) : removeElement}
                    >
                        {editing ?
                            <SaveIcon/> : props.new ? <AddIcon/> : <DeleteIcon/>}
                    </IconButton>
                </ButtonGroup>
            }>
            <ListItemText
                primary={titleEl}
                secondary={contentEl}/>
        </ListItem>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callbackFcn) => dispatch(actions.writeToDB(option,type,level,where,data,callbackFcn)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
    }
}

export default connect(null,mapDispatchToProps)(OptionElement);