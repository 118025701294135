import React, {useState} from 'react';
import {ButtonGroup, IconButton, TextField, Typography} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import Card from "../../UI/Card/Card";
import ProgressBar from "../../GRIDisplay/ProgressBar/ProgressBar";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

import * as actions from "../../../store/actions";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

function EntityCard(props) {

    const [adding, setAdding] = useState(false)
    const [title, setTitle] = useState(props.title)

    const addEntity = () => {
        props.writeToDB('update','reports','entity',null,{report_id: props.currentReport, set_id: props.currentSet, title: title},
            () => props.getData('reports','entity',{},[],'replace'))
        setAdding(false)
    }

    const removeEntity = () => {
        if (window.alert)
        props.writeToDB('remove','reports','entity',{id: props.id},{},
            () => props.clearDataEntry('reports','entity',props.id)
        )
    }

    let titleEl, bodyEl, actionEl

    const styles = {
        boxSizing: 'border-box',
        margin: '0.5rem 0.25rem',
        padding: '0.5rem',
        bgcolor: '#eee',
        width: 250
    }

    if (props.new) {
        titleEl = adding ? <TextField
            variant='standard'
            label='Enter a report title'
            onChange={(event) => setTitle(event.target.value)}
        >{title}</TextField> : <Typography variant='h6'>Add entity</Typography>

        bodyEl = <Typography>
            {'Use the "' + props.reports_data.report[props.currentReport].title + '" blueprint to create a new report.'}
        </Typography>

        actionEl = <ButtonGroup sx={{margin:'auto'}}>
            {adding ? <>
                    <IconButton onClick={() => setAdding(false)}><DoNotDisturbIcon /></IconButton>
                    <IconButton onClick={addEntity}><SaveIcon /></IconButton>
                </>:
                <IconButton onClick={() => setAdding(true)}><AddIcon /></IconButton>}
            </ButtonGroup>
    } else {
        titleEl = <Typography variant='h6'>{title}</Typography>

        bodyEl = <>
            <Typography>{props.description}</Typography>
            <ProgressBar progress={props.progress ?? 0}/>
        </>

        actionEl = <ButtonGroup sx={{margin:'auto'}}>
            <IconButton  onClick={() => {
                props.changeReportEntity(props.id)
                props.history.push({pathname:'/admin/reports/submit', search:'?report='+props.currentReport+'&entity='+props.id})
            }}>
                <EditIcon/>
            </IconButton>
            <IconButton>
                <VisibilityIcon/>
            </IconButton>
            <IconButton onClick={removeEntity}>
                <DeleteIcon/>
            </IconButton>
        </ButtonGroup>
    }


    return <Card
        title={titleEl}
        body={bodyEl}
        action={actionEl}
        styles={styles}
    />
}

const mapStateToProps = state => {
    return {
        reports_data: state.data.reports,

        currentSet: state.data.currentMeasureSet,
        currentReport: state.data.currentReport,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false)),
        writeToDB: (option,type,level,where,data,callbackFcn) => dispatch(actions.writeToDB(option,type,level,where,data,callbackFcn)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
        changeReportEntity: (id) => dispatch(actions.changeReportEntity(id))
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps,mapDispatchToProps)
)(EntityCard);
