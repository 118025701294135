import React, {useContext, useMemo, useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Box, Stack, Typography, Badge} from "@mui/material";
import {sortGRI} from "../../../shared/utility";
import {connect} from "react-redux";
import DisclosureList from "../Disclosure/DisclosureList";
import Goals from "../../Lists/Goals/Goals";
import Ratings from "../Ratings/Ratings";
import ProgressBar from "../ProgressBar/ProgressBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import * as actions from "../../../store/actions";
import InspireList from "../../Inspire/InspireList";
import {DisplayContext} from "../../../shared/DisplayContext";
import MeasureList from "../../Measures/MeasureList";

import classes from "../GRIDisplay.module.scss";


function Subtopic(props) {
    // console.log('[Subtopic.js] rerendering')

    const context = useContext(DisplayContext)
    const [expanded, setExpanded] = useState(false)
    const [projectsLoaded, setProjectsLoaded] = useState(false)
    const [policiesLoaded, setPoliciesLoaded] = useState(false)
    const [changed,setChanged] = useState(false)

    const griFilter = {dimension:props.dimension, topic:props.topic, subtopic:props.subtopic}

    const expandObject = () => {

        if (!context.editRights) {

            props.getData('measures','timeful',{...griFilter, set_id:props.currentSet, ...context.additionalFilter},[],'add',() => setExpanded(!expanded))
            props.getData('inspire','projects',griFilter,[],'add',
                () => setProjectsLoaded(true)
            )
            props.getData('inspire','policies',griFilter,[],'add',
                () => setPoliciesLoaded(true)
            )
            props.getData('measures','data',{...griFilter, set_id:props.currentSet, ...context.additionalFilter},[],'add')
        } else {
            props.getData('gri','disclosure',{...griFilter, set_id:props.currentSet, ...context.additionalFilter},[],'add')
            props.getData('measures','timeful',{...griFilter, set_id:props.currentSet, ...context.additionalFilter},[],'add',() => setExpanded(!expanded))
        }
    }

    // calculating number of measures already provided with data
    let cts = props.count_data.data['subtopic'][props.GRI] ?? 0
    const progress = props.gri_data.subtopic[props.id]?.progress

    return useMemo(() => {
        // console.log('[Subtopic.js] (Memo) rerendering')
        const filterByGRI = (el) => {
            return el.subtopic===props.subtopic && el.topic===props.topic && el.dimension===props.dimension
        }

        const disclosures = Object.values(props.gri_data.disclosure).filter(filterByGRI)
        disclosures.sort(sortGRI)

        let content
        if (expanded) {
            if (context.editRights) {
                content = <DisclosureList data={disclosures} onChange={()=>setChanged(!changed)}/>
            } else {
                const measuresTracked = Object.values(props.measures_data.timeful).filter(el => {
                    return el.subtopic===props.subtopic && el.topic===props.topic && el.dimension===props.dimension && el.progress_tracked===1 && props.count_data.data.measure_id[el.id]>0
                })
                const measuresUntracked = Object.values(props.measures_data.timeful).filter(el => {
                    return el.subtopic===props.subtopic && el.topic===props.topic && el.dimension===props.dimension && el.progress_tracked===0 && props.count_data.data.measure_id[el.id]>0
                })
                const measuresEl = <Box>
                    <MeasureList data={measuresTracked}/>
                    <hr/>
                    <MeasureList data={measuresUntracked}/>
                </Box>

                const projects = Object.values(props.inspire_data.projects).filter(p => p.subtopic_id.includes(props.id))
                const policies = Object.values(props.inspire_data.policies).filter(p => p.subtopic_id.includes(props.id))

                const inspiresEl = <Box>
                    {(projectsLoaded && projects.length>0) && <Box sx={{width:'100%'}}>
                        <Typography variant={'h2'}>Projects</Typography>
                        {/*<p>Some list of projects, showing best practices around this GRI</p>*/}
                        <InspireList data={projects} />
                    </Box>}
                    {(policiesLoaded && policies.length>0) && <Box sx={{width:'100%'}}>
                        <Typography variant={'h2'}>Policies</Typography>
                        {/*<p>References or descriptions of policies relating to this GRI, which could be adapted by regions or NSOs</p>*/}
                        <InspireList data={policies} />
                    </Box>}
                </Box>
                content = <>
                    {measuresEl}
                    {inspiresEl}
                </>
            }
        }


        let badgeColor
        if (cts===0)
            badgeColor = 'error'
        else if (cts===parseInt(props.count_data.timeful['subtopic'][props.GRI]))
            badgeColor = 'success'
        else
            badgeColor = 'warning'

        // if (!context.editRights && props.count_data.===0) {
        //     return null
        // }

        return (
            <Accordion
                key='measure'
                expanded={expanded}
                TransitionProps={{ unmountOnExit: true }}
                sx={{
                    backgroundColor: '#ddd',
                    margin: '0.5rem 0.3rem', padding:'0.3rem 0.5rem',
                    borderRadius:'3px',
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
                }}>
                <Badge invisible={!context.editRights} sx={{width:'100%', top:'0.5rem'}} badgeContent={cts + ' / ' + props.count_data.timeful['subtopic'][props.GRI]} color={badgeColor}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon onClick={expandObject}/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{width:'100%', margin: '0',padding:'0'}}
                    >
                    <Stack direction={'row'} className={classes.SubtopicHeader}>
                        <div className={classes.SubtopicHeader__Rating}>
                            <Ratings data={props}/>
                        </div>
                        <div className={classes.SubtopicHeader__Title}>
                            <p><strong>GRI {props.GRI}:</strong> {props.description}</p>
                        </div>
                        <div className={classes.SubtopicHeader__Goals}>
                            <Goals
                                goals={props.gri_data.subtopic[props.id].SDG_goal}
                                size={25}
                                editable={context.adminRights}
                                    onEdit={(goal_id)=>context.selectGoal(props.dimension,props.topic,props.subtopic,'',goal_id,'',() => props.getData('gri','subtopic',{
                                    'dimension':props.dimension,
                                    'topic':props.topic,
                                    'subtopic': props.subtopic,
                                },[],'add',()=>setChanged(!changed)))}/>
                        </div>
                        <div className={classes.SubtopicHeader__Progress}>
                            <ProgressBar {...props} progress={progress} title={parseFloat(progress).toFixed(1) + '% progress towards this GRI'}/>
                        </div>
                    </Stack>
                    </AccordionSummary>
                </Badge>
                <AccordionDetails sx={{padding: '8px 0'}}>
                    {content}
                </AccordionDetails>
            </Accordion>
        );
    },[expanded,changed,props.count_data.timeful['subtopic'][props.GRI],cts,progress])
}

const mapStateToProps = state => {
    return {
        measures_data: state.data.measures,
        gri_data: state.data.gri,
        inspire_data: state.data.inspire,

        count_data: state.data.counts,

        currentSet: state.data.currentMeasureSet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Subtopic);