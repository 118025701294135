import React from 'react';

import Measure from './Measure';
import classes from './Measures.module.scss';

const MeasureList = (props) => {

    // console.log('[MeasureList.js] rerendering')
    const measureEls = props.data.map(d =>
        <Measure
            key={d.id}
            {...d}
        />
    )

    if (props.adding) {
        measureEls.push(<Measure
            key={'add'}
            new={true}
            {...props}
            disclosure_id={props.disclosure_id}
        />)
    }

    if (measureEls.length>0) {
        return <div className={classes.ListMeasures}>
            {measureEls}
            {/*{props.adminRights ? <Button type="add" clicked={() => props.addMeasure()}/> : null}*/}
        </div>
    } else {
        return null
    }
}

export default MeasureList;