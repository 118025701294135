
const prod = {
    url: {
        API_URL: 'https://sustainabilitymonitor.scouthub.org/api/',
    }
}

const dev = {
    url: {
        API_URL: 'http://localhost/api/',
    }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
