import React from 'react';

// import classes from './About.module.scss';
import {Typography} from "@mui/material";

const About = (props) => {

    return (
        <div>
        <Typography variant='h1'>About</Typography>

            <Typography variant='h2' id="sdg">SDG</Typography>
            <Typography variant='body1'>
                Scouting’s action-oriented approach to education is uniquely designed to equip young people with the competencies to become active global citizens and contribute to peace and sustainable development.<br/>
                Over the years Scouting has made any extraordinary contribution to improve the sustainability of our planet, promote peace, and tackle inequality. Already Scouts have contributed more than two billion hours towards sustainable development through World Scouting's flagship intiatives.<br/>
                Now, as a global Movement, we are taking that commitment one step further with Scouts for SDGs - an unprecedented mobilization is engaging 54 million Scouts to make the world’s largest coordinated youth contribution to the Sustainable Development Goals by 2030.<br/>
                Along the way we plan to deliver a total of 4 billion hours of community service towards the SDGs. Scouts for SDGs is more than just a campaign or promise, it’s a systematic effort to leverage Scouting's Youth Programme and initiatives under the Better World Framework, including Messengers of Peace, to raise awareness and take action for the SDGs.<br/>
                Scouts for SDGs features this online SDGs hub and a range of educational tools, training and resources developed to support National Scout Organizations in aligning their Youth Programme and other activities with the SDGs.<br/>
                This initiative also leverages financial and in-kind contributions from the World Scout Foundation and a wide network of partners, including United Nations agencies and non-governmental organizations, to support local projects led by Scouts.
            </Typography>
            <br/>

            <Typography variant='h2' id='monitoring'>Why monitoring?</Typography>
            <Typography variant='body1'>
                With our work, we aim to build a good understanding of the different impact that all teams active within the regional framework have on the overall sustainability of the WOSM European region. This overview is crucial in identifying the most important topics to act upon when striving to become as sustainable as possible.<br/>
                It is thus our goal to monitor and quantify as fully as possible the different impact that the office, teams and further groups connected to the European Region have on sustainability. This data will be fed into a monitoring system, which allows our members and further interested parties to see how we're doing and where we want to go.<br/>
                 <br/>
            </Typography>

            <Typography variant='h2' id="gri">The GRI Standards</Typography>
            <Typography variant='body1'>
                The GRI Standards represent a global best practice for reporting publicly on a range of economic, environmental and social impacts and consists of Universal Standards and Topic-specific Standards which contain disclosures and supporting requirements for disclosures.<br/>
                Sustainability reporting based on the GRI Standards helps organizations increase their transparency and communicate both their positive and negative impacts on sustainable development. The GRI Standards enable consistent and high-quality sustainability reporting, which helps organizations meet the needs of their stakeholders for comparable and reliable data.<br/>
                Each organization reports on topics that reflect the organization’s significant economic, environmental, and social impacts and that are important to its stakeholders. With the GRI Standards, these are the “material topics” that the organization reports on. An organization’s material topics are determined based on the organization’s activities and business relationships. The GRI Standards define a set of principles for organizations to define the content of their reports.<br/>
                The GRI Standards are structured as a set of interrelated standards. There are three universal Standards that apply to every organization preparing a sustainability report. An organization then selects from the set of topic specific GRI Standards for reporting on its material topics.<br/>
                The topic specific GRI Standards are organized into four series:
            </Typography>
            <ul>
                <Typography variant='body1'>
                    <li>100 series (Universal topics) – supports the organization in identifying its material topics, and lay out important principles to use when preparing a report. They also contain disclosures on the organization’s specific context, such as its size, activities, governance, and stakeholder engagement, all of which help to better understand its approach towards the different topics it reports on.</li>
                    <li>200 (Economic topics) - it concerns an organization’s impacts on the economic conditions of its stakeholders, and on economic systems at local, national, and global levels. It does not focus on the financial condition of an organization.</li>
                    <li>300 (Environmental topics) – it concerns an organization’s impacts on living and non-living natural systems, including land, air, water and ecosystems. and 400 (Social topics).</li>
                    <li>400 series (Social topics) – it concerns an organization’s impacts on the social systems within which it operates.</li>
                </Typography>
            </ul>
        </div>
    )
}

export default About;
