import React, { Component } from 'react';
import { connect } from 'react-redux';
import InspireList from '../../../components/Inspire/InspireList';
import Spinner from '../../../components/UI/Spinner/Spinner';

import * as actions from '../../../store/actions';


class Inspire extends Component {

    state = {
        where: null,
        type: null
    }

    loadData() {
        const type = this.props.location.pathname.split("/").at(-1);
        this.setState({type: type})
        // console.log('load', type)
        // this.props.resetData()
        // this.props.getData('sdg','goal',{},[],'replace')
        this.props.getData('gri','subtopic',{},[],'replace')
        this.props.getData('inspire',type,{},[],'replace')
    }

    componentDidMount () {
        this.loadData()
    }

    componentDidUpdate () {
        const type = this.props.location.pathname.split("/").at(-1);
        if (type!==this.state.type) {
            this.loadData()
        }
    }


    render () {

        // const createFile = async (url) => {
        //     const response = await fetch(url)
        //     const data = await response.blob()
        //
        //     const fileType = data.type
        //     const ext = fileType.split('/')[1]
        //     let metadata = {
        //         type: fileType
        //     };
        //     let file = new File([data], "upload_"+Math.random().toString(30).substr(2, 36) +"."+ext, metadata);
        //     const values = {...this.state.values}
        //     values['file'] = file
        //     this.setState({values: values})
        // }



        let list = <Spinner />
        if (this.state.type && this.props.loadStatus[this.state.type].loaded && !this.props.loadStatus[this.state.type].loading) {
            list = <InspireList data={this.props.inspire_data[this.state.type]} editRights={true}/>
        }

        return (
            <div>
                <h1 style={{textTransform: 'capitalize'}}>{this.state.type}</h1>
                <p>This is the admin section. Only the office and volunteer teams working for the region will have access to this. You are able to edit and add entries - please refrain from deleting any entries, or introducing major changes - it will only hinder our progress :(</p>
                {list}
            </div>
        )
    }
    // changed={(event) => this.selectionChangedHandler(event,el)}
}

const mapStateToProps = (state) => {
    return {
        loadStatus: state.data.loadStatus,
        GRI_data: state.data.gri,
        inspire_data: state.data.inspire
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getMeasures: (level,published) => dispatch(actions.getMeasures(level,published)),
        resetData: () => dispatch(actions.resetData()),
        getData: (type,level,filter,links,setOption,setLoading,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,setLoading,callbackFcn)),
        // getStats: (type,level,filter,setOption) => dispatch(actions.getData('fetchStats`',type,level,filter,setOption)),
        writeToDB: (level,where,data,callbackFcn) => dispatch(actions.writeToDB('update','inspire',level,where,data,callbackFcn)),
        // setLoading: (type,level) => dispatch(actions.setLoadStatus(type,level,true,false)),
        // changeMeasureSet: (id) => dispatch(actions.changeMeasureSet(id)),
        // selectSubtopic: (id,reset) => dispatch(actions.selectSubtopic(id,reset))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Inspire)
