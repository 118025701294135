import React, {useContext, useMemo, useState} from 'react';
import {connect} from "react-redux";
import Topic from "./Topic";
import {Box} from "@mui/material";
import {DisplayContext} from "../../../shared/DisplayContext";

function TopicList(props) {

    const context = useContext(DisplayContext)
    let filterIt = context.filterIt
    // console.log('[TopicList] rerendering')

    const [expanded,setExpanded] = useState(null)

    return useMemo(() => {
        // console.log('[TopicList] (memo) rerendering')
        const toggleOpen = (id) => {
            setExpanded(id===expanded ? null : id)
        }

        let topicEls = null
        if (props.GRI_data.topic) {

            const topics = filterIt ? Object.values(props.GRI_data.topic).filter(t => props.measure_counts['topic'][t.GRI]>0) : Object.values(props.GRI_data.topic)
            // console.log(filterIt,topics)
            topicEls = topics.map(t =>
                <Topic
                    key={t.id}
                    expanded={expanded}
                    toggleOpen={toggleOpen}
                    {...t}
                />
            )
        }

        return <Box>{topicEls}</Box>
    },[filterIt,expanded])
}


const mapStateToProps = state => {
    return {
        GRI_data: state.data.gri,
        measure_counts: state.data.counts.timeful,
    }
}

export default connect(mapStateToProps)(TopicList);