import React, {Component} from 'react';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';

import classes from './Login.module.scss';
import {
    Box,
    Button,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import TabPanel from "../../../components/UI/Tabs/TabPanel";
import {Redirect} from "react-router-dom";

class Login extends Component {

    state = {
        userInfo: {
            name:'',
            email:'',
            password:'',
            repeat_password:'',
        },
        errorMsg:'',
        tab: 0,
    }

    // On change input value (email & password)
    onChangeValue = (e) => {
        this.setState({
            ...this.state,
            userInfo:{
                ...this.state.userInfo,
                [e.target.name]:e.target.value
            }
        });
    }

    componentDidMount () {
        this.props.isLoggedIn()
    }

    render () {

        // console.log('checking')
        if (this.props.isAuth)
            return <Redirect to={'/admin/manageMeasures'}/>

        return(
            <div className={classes.Login}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={this.state.tab} onChange={(event,new_value) => this.setState({tab:new_value})} aria-label="basic tabs example">
                        <Tab label="Log In" id='tab-login' aria-controls='tabpanel-0'/>
                        <Tab label="Register" id='tab-register' aria-controls='tabpanel-1'/>
                    </Tabs>
                </Box>
                <TabPanel value={this.state.tab} index={0}>
                    <Stack direction={'column'}>
                        <Typography variant={'body1'}>Some description of the login page</Typography>
                        <TextField
                            label='Email' name="email"
                            required
                            value={this.state.userInfo.email}
                            onChange={this.onChangeValue} />
                        <TextField
                            label='Password' name="password"
                            type="password"
                            required
                            value={this.state.userInfo.password}
                            onChange={this.onChangeValue} />
                        <Button type="submit" onClick={() => this.props.loginUser(this.state.userInfo)}>Login</Button>
                    </Stack>
                </TabPanel>
                <TabPanel value={this.state.tab} index={1}>

                </TabPanel>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user_data: state.data.user,
        loadStatus: state.data.loadStatus,

        isAuth: state.auth.isAuth,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginUser: (userInfo) => dispatch(actions.loginUser(userInfo)),
        isLoggedIn: () => dispatch(actions.isLoggedIn()),
        registerUser: (userInfo) => dispatch(actions.registerUser(userInfo)),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Login);
