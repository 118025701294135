import React from 'react';

import classes from './Ratings.module.scss';
import {Tooltip} from "@mui/material";

const Ratings = (props) => {

    const colors = ['grey','#622599','red']
    const ratingsEl = ['applicability','priority','measurability'].map((el,i) => {
        return <div key={el}
            style={{
                width: '30%',
                height: (props.data[el]*10)+'%',
                backgroundColor: colors[i],
            }}>
        </div>
    })

    return <Tooltip
        key='ratings'
        title={"Applicability: "+props.data['applicability']?.toFixed(1)+", Priority: "+props.data['priority']?.toFixed(1) + ", Measurability: "+props.data['measurability']?.toFixed(1)}
        enterTouchDelay={10}>
        <div className={classes.BarGraph}>
            {ratingsEl}
            <div className={classes.maxIndicator}></div>
            <div className={classes.minIndicator}></div>
        </div>
    </Tooltip>
}

export default Ratings;
