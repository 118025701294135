import React from 'react';

import classes from './Box.module.scss';

const Box = (props) => {

    // const [active,setActive] = useState(props.active)

    const divClasses = props.divClasses ?? []
    divClasses.unshift(classes.Box)
    const clicked = props.clicked ? props.clicked : () => {}

    return <div className={divClasses.join(' ')}>
        <div
            className={classes.Box__header}
            onClick={
                ()=> {
                    // setActive(!active)
                    clicked()
            }}
        >
            {props.header}
        </div>
        {props.active ? <div className={classes.Box__content}>{props.content}</div> : null}
    </div>
}

export default Box;
