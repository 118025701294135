import React from 'react';
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from "@mui/material";

function MultipleSelect(props) {

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={props.values}
                    onChange={(event) => props.setValues(event.target.value)}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    // MenuProps={MenuProps}
                >
                    {props.data.map(d => {
                        return <MenuItem key={d.id} value={d.id}>
                            <Checkbox checked={props.values.indexOf(d.id) > -1}/>
                            <ListItemText primary={d.title}/>
                        </MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export default MultipleSelect;