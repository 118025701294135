import React, {useState} from 'react';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select, Stack,
} from "@mui/material";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import TextField from "@mui/material/TextField";
import OptionList from "../Lists/OptionList";

function ListSets(props) {

    const [newSet, setNewSet] = useState({
        title: '',
        description: '',
        ref_id: props.currentSet,
    })

    const [valid, setValid] = useState(false)

    const changeData = (target) => {
        const key = target.name
        const val = target.value
        const updatedReport = {...newSet, [key]:val}
        validate(updatedReport)
        setNewSet(updatedReport)
    }

    const validate = (item) => {
        let newValid = true
        newValid = newValid && item.title.toString().length>0
        newValid = newValid && item.description.length>0
        // newValid = newValid && item.ref_id>0

        setValid(newValid)
    }

    // const [showDescription, setShowDescription] = useState(false)

    //
    // const addSet = (id=null) => {
    //     if (props.admin) {
    //         if (newSet.id) {
    //             delete newSet.id
    //         }
    //         props.writeToDB('update', 'measures', 'set', id ? {id: id} : {}, newSet,
    //             () => props.getData('measures','set',{},[],'replace',()=>setEditing(null))
    //         )
    //     }
    // }
    //
    // const editSet = (id=null) => {
    //
    //     if (props.admin) {
    //         setEditing(id)
    //
    //         setNewSet(id===0 ?
    //             {
    //                 title: '',
    //                 description: '',
    //                 ref_id: props.currentSet,
    //             } : {...props.measures_data.set[id]}
    //         )
    //     }
    // }
    //
    // const removeSet = (id) => {
    //     if (props.admin) {
    //         const str = 'Are you sure you want to delete this set? All assignments of measures will be lost.'
    //         if (window.confirm(str)) {
    //             props.writeToDB('remove', 'measures', 'set', {'id': id}, {},
    //                 () => props.clearDataEntry('measures','set',id)
    //             )
    //         }
    //     }
    // }

    const buildItem = (item,editing,isNew=false) => {

        // building select options
        let primary, secondary
        if (editing) {
            primary = <div>
                <Stack direction={'row'} gap={2} sx={{justifyContent:'space-between', marginBottom:'1rem'}}>
                    <TextField
                        id='setName'
                        label={'Specify set name'}
                        name='title'
                        variant='standard'
                        error={!newSet.title}
                        value={newSet.title}
                        onChange={(event) => changeData(event.target)}/>
                    {isNew && <FormControl sx={{width: '8rem'}}>
                        <InputLabel id="selectReferenceSet">Reference set</InputLabel>
                        <Select
                            id="selectReferenceSet" labelId="selectReferenceSet_label" label="Type"
                            value={newSet.ref_id}
                            name='ref_id'
                            variant='standard'
                            onChange={(event) => changeData(event.target)}
                        >
                            {Object.values(props.measures_data.set).map(t => (
                                <MenuItem key={t.id} value={t.id} sx={{width: '7rem'}}>{t.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                </Stack>
                <TextField
                    id='setDescription'
                    label='Set description'
                    name='description'
                    multiline fullWidth
                    error={!newSet.description}
                    value={newSet.description}
                    rows={4}
                    onChange={(event) => changeData(event.target)}/>
            </div>
            secondary = null
        } else {
            primary = item.title
            secondary = item.description
        }
        return {primary: primary, secondary: secondary}
    }

    return <OptionList
        data={props.measures_data.set}
        title={props.title}
        type='measures' level='set'
        selected={props.currentSet}
        admin={props.admin}
        valid={valid}
        newItem={newSet}
        setNewItem={setNewSet}
        buildItem={buildItem}
        changeItem={props.changeSet}
    />
    // (
    //     <List>
    //         {props.title && <Typography variant='h4'>{props.title}</Typography>}
    //         {set_list}
    //     </List>
    // );
}

const mapStateToProps = state => {
    return {
        measures_data: state.data.measures,

        currentSet: state.data.currentMeasureSet,
        loadStatus: state.data.loadStatus,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: (type,level,filter,links,setOption,callbackFcn) => dispatch(actions.getData('fetch',type,level,filter,links,[],setOption,false,callbackFcn)),
        writeToDB: (option,type,level,where,data,callbackFcn) => dispatch(actions.writeToDB(option,type,level,where,data,callbackFcn)),
        clearDataEntry: (type,level,id) => dispatch(actions.clearDataEntry(type,level,id)),
        changeSet: (id) => dispatch(actions.changeMeasureSet(id)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ListSets);